.gallery-cards {
    display: flex;
    flex-wrap: wrap;
    margin: rem-calc(0 -15);

    @include media-breakpoint-up(md) {
        margin-inline: rem-calc(-20);
    }

    .slick-arrow {
        @include animate(background);
        width: rem-calc(40);
        height: rem-calc(37);
        order: 10;
        background: $gray-600;
        color: $white;

        @include media-breakpoint-up(md) {
            width: rem-calc(50);
            height: rem-calc(47);
        }

        .is-not-touch-device & {
            &:hover {
                color: $white;
                background: $cyan-900;
            }
        }

        &.slick-prev {
            margin: rem-calc(30 4) 0 auto;
            border-radius: rem-calc(30 0 0 30);
        }

        &.slick-next {
            margin: rem-calc(30) auto 0 rem-calc(4);
            border-radius: rem-calc(0 30 30 0);
        }
    }

    .slick-list,
    .slick-track {
        display: flex;
    }

    .slick-slide {
        display: flex;
        margin: rem-calc(0 15);

        @include media-breakpoint-up(md) {
            margin-inline: rem-calc(20);
        }
    }
}