// Typography
//

body {
    min-width: 375px;
    font-size: $font-size-base;
    line-height: $line-height-base;

    @include media-breakpoint-up(sm) {
        line-height: $line-height-lg;
        font-size: $font-size-lg;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    a {
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

h1,
.h1 {
    font-size: $h1-font-size;
    margin: rem-calc(0 0 33);
    letter-spacing: .03em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(41);
        line-height: calc(50/41);
        margin-bottom: rem-calc(23);
    }
}

h2,
.h2 {
    font-size: $h2-font-size;
    letter-spacing: .03em;
    margin: rem-calc(0 0 30);
    margin: rem-calc(0 0 20);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(36);
        line-height: calc(43/36);
        margin-bottom: rem-calc(30);
    }

    &.title-md {
        @include media-breakpoint-up(md) {
            font-size: rem-calc(34);
            line-height: calc(41/34);
        }
    }
}

h3,
.h3 {
    font-size: $h3-font-size;
    letter-spacing: .03em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(30);
        line-height: calc(36/30);
        margin-bottom: rem-calc(30);
    }
}

h4,
.h4 {
    font-size: $h4-font-size;
    letter-spacing: .03em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(26);
        line-height: calc(26/26);
    }
}

h5,
.h5 {
    font-size: $h5-font-size;
    letter-spacing: .03em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(22);
        line-height: calc(26/22);
    }
}

h6,
.h6 {
    font-size: $h5-font-size;
    letter-spacing: .03em;
    line-height: calc(23/20);
}

a {
    @include animate(box-shadow color background border opacity transform width height text-decoration-color);
    text-decoration: underline;
    color: $btn-link-color;
    text-decoration-color: currentColor;

    &:hover {
        text-decoration-color: transparent;
        color: $btn-link-hover-color;
    }
}

.lead {
    line-height: calc(36/19);
    letter-spacing: .03em;
    margin: 0 0 1.25rem;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(22);
        line-height: calc(36/22);
    }
}

hr {
    background: $gray-500;

    @include media-breakpoint-up(md) {
        height: rem-calc(2);
    }
}
