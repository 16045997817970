.calendar-tabs {
    .filters-holder {
        border-width: rem-calc(5 1 1);
    }
}

.tabset {
    @extend %listreset;
    display: flex;
    border: rem-calc(1) solid $primary;
    border-width: rem-calc(1 1 1 0);
}

.tabset-item {
    flex: 1 0;
    text-align: center;
    font: 1rem/calc(22/18) $font-family-secondary;
    border-left: rem-calc(1) solid $primary;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(18);
    }
}

.tabset-button {
    @include animate(background color);
    width: 100%;
    padding: rem-calc(10);
    border: 0;
    background: transparent;
    color: $primary;
    font-weight: 500;

    &.active {
        background: $primary;
        color: $white;
    }

    .is-not-touch-device & {
        &:hover {
            background: $primary;
            color: $white;
        }
    }
}