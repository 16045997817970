.breadcrumb {
    display: block;
    margin: rem-calc(0 0 10);
}

.breadcrumb-item {
    color: $gray-900;
    display: inline;
    padding: 0;

    +.breadcrumb-item {
        position: relative;
        padding: rem-calc(0 0 0 12);
        margin: rem-calc(0 0 0 4);
        letter-spacing: .02em;

        &:before {
            position: absolute;
            top: rem-calc(7);
            left: 0;
            content: '';
            width: rem-calc(4);
            height: rem-calc(4);
            border-radius: 50%;
            background: #C4BFBF;
            padding: 0;
        }
    }

    a {
        color: $blue-200;
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }
}