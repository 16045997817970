.events-info {
    margin: rem-calc(0 0 30);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(40);
    }

    &:last-child {
        margin-bottom: 0;
    }

    .simplebar-content-wrapper {
        max-height: rem-calc(425);

        @include media-breakpoint-up(md) {
            padding: rem-calc(0 10 0 0);
        }

        &.simplebar-secondary {
            .simplebar-scrollbar {
                &:before {
                    background: $info;
                }
            }
        }
    }

    .info-items {
        padding: rem-calc(25 10 10 21);
    }
}

.events-info-title {
    display: flex;
    background: $light;
    padding: rem-calc(10 20);

    @include media-breakpoint-up(md) {
        padding-inline: rem-calc(40);
    }

    h4,
    .h4 {
        font-size: rem-calc(18);
        line-height: calc(23/20);
        color: $info;
        margin: 0;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
        }
    }

    .bi {
        font-size: rem-calc(17);
        line-height: 1;
        margin: rem-calc(0 13 0 0);
        color: $cyan-900;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(19);
        }
    }
}