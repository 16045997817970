.card {
    width: 100%;
    font-size: rem-calc(15);
    line-height: calc(23/15);
    letter-spacing: .02em;
    color: $gray-900;

    .card-image {
        position: relative;
        display: block;
        padding-top: 61%;
        overflow: hidden;

        img {
            @include animate(transform);
            @include absolute;
            @extend %imgcover;
        }
    }

    a.card-image {
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    p {
        margin: 0 0 1em;
    }

    h4,
    .h4 {
        font-size: rem-calc(17);
        line-height: calc(22/17);
        margin: rem-calc(0 0 5);
    }

    .card-body {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            padding-right: rem-calc(30);
        }

        &:last-child {
            padding-bottom: rem-calc(40);
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .card-title {
        margin: rem-calc(0 0 26);

        >* {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    time {
        display: block;
        font: 700 .8125rem/calc(12/13) $font-family-secondary;
        color: $gray-700;
    }
}

.cards-horizontal-holder {
    &:last-child {
        margin-bottom: rem-calc(-30);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(-42);
        }
    }

    .card-horizontal {
        margin: rem-calc(0 0 30);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(42);
        }
    }
}

.card-horizontal {
    color: $body-color;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        font-size: rem-calc(18);
        line-height: calc(32/18);
    }

    .card-image {
        @include media-breakpoint-up(md) {
            width: rem-calc(345);
            padding-top: 0;
        }

        img {
            @include media-breakpoint-up(md) {
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                right: auto;
            }
        }
    }

    .card-body {
        @include media-breakpoint-up(md) {
            flex: 1 0;
            padding: rem-calc(15 36);
        }

        &:last-child {
            @include media-breakpoint-up(md) {
                padding-bottom: rem-calc(10);
            }
        }
    }

    .card-title {
        position: relative;
        z-index: 1;
        padding: rem-calc(0 0 15 6);
        margin: rem-calc(0 0 25);

        &:after {
            position: absolute;
            width: rem-calc(20);
            height: rem-calc(5);
            content: "";
            bottom: 0;
            left: rem-calc(6);
            background-image: radial-gradient($cyan-900 rem-calc(1.5), transparent 0);
            background-size: rem-calc(5 5);
            z-index: -1;
        }
    }

    h6,
    .h6 {
        color: $blue-200;
    }

    .btn {
        &.btn-link {
            color: $cyan-900;
            text-transform: uppercase;
            font-size: rem-calc(12);
            line-height: calc(16/12);
            font-weight: 900;
            font-family: 'avenir-heavy', 'Helvetica Neue', 'Arial', sans-serif;
        }
    }
}

.cards-program-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin: rem-calc(0 -20);

    @include media-breakpoint-up(md) {
        margin-inline: rem-calc(-40);
    }

    @include media-breakpoint-up(lg) {
        margin-inline: rem-calc(-51);
    }

    &:last-child {
        margin-bottom: rem-calc(-50);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(-78);
        }
    }

    .card-program {
        margin: rem-calc(0 20 50);

        @include media-breakpoint-up(md) {
            margin: rem-calc(0 40 78);
        }

        @include media-breakpoint-up(lg) {
            margin-inline: rem-calc(51);
        }
    }
}

.card-program {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: rem-calc(300);
    text-align: center;
    background: $light;
    border-top: rem-calc(33) solid $cyan;
    font-size: rem-calc(15);
    line-height: calc(27/15);

    .card-head,
    .card-body,
    .card-footer {
        padding-inline: rem-calc(23);
    }

    .card-head {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: rem-calc(90);
        padding-block: rem-calc(10);

        &:after {
            position: absolute;
            content: '';
            height: rem-calc(1);
            left: rem-calc(33);
            right: rem-calc(33);
            bottom: 0;
            background: $gray-400;
        }

        >* {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .card-body {
        flex: 1 0;
        padding-block: rem-calc(23);

        >* {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .card-footer {
        padding: rem-calc(7 24 24);
    }

    h3 {
        color: $blue-200;
        font-size: rem-calc(18);
        line-height: calc(22/18);
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.card-person {
    width: 100%;
    border-bottom: $border-width solid $gray-400;
    margin: rem-calc(0 0 26);
    padding: rem-calc(0 0 28);
    font-size: rem-calc(16);
    line-height: 1.55;
    letter-spacing: .04em;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    a {
        color: $cyan-900;
    }

    h3 {
        font-size: rem-calc(20);
        line-height: calc(26/22);
        margin: rem-calc(0 0 22);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(22);
        }
    }

    .image-card {
        width: rem-calc(170);
        height: rem-calc(170);
        margin: rem-calc(0 0 30);

        @include media-breakpoint-up(md) {
            width: rem-calc(150);
                height: rem-calc(150);
            margin: rem-calc(0 40 0 0);
        }

        @include media-breakpoint-up(lg) {
            margin-right: rem-calc(60)
        }

        @include media-breakpoint-up(xl) {
            margin-right: rem-calc(70);
        }

        img {
            @extend %imgcover;
        }
    }

    .card-boby {
        @include media-breakpoint-up(md) {
            flex: 1 0;
        }
    }

    p {
        margin: 0;
    }

    a {
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }
}