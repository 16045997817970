.form-select {
    display: flex;
    align-items: center;
}

.form-select,
.form-control {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(18);
        height: rem-calc(50);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(20);
    }
}

.reset-holder {
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:focus {
            +label {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
                outline: 0;
                -webkit-box-shadow: var(--bs-btn-focus-box-shadow);
                box-shadow: var(--bs-btn-focus-box-shadow);
            }
        }

        +label {
            cursor: pointer;
            letter-spacing: .04em;

            .bi {
                margin: rem-calc(0 -15 0 12);
                font-size: rem-calc(15);
                line-height: 1;
            }
        }
    }
}
