.accordion {
    margin: rem-calc(35 0);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.accordion-button {
    position: relative;
    font: 700 1.25rem/calc(25/22) $font-family-secondary;
    letter-spacing: .05em;
    padding: rem-calc(15 0 15 50);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(22);
        padding: rem-calc(24 0 24 60);
    }

    &:after,
    &:before {
        @include animate(transform);
        content: '';
        position: absolute;
        width: rem-calc(4);
        height: rem-calc(16);
        left: rem-calc(20);
        background: $success;
        top: 50%;
        transform: translateY(-50%);
    }

    &:after {
        background-image: none;
    }

    &:before {
        height: rem-calc(4);
        width: rem-calc(16);
        left: rem-calc(14);
    }

    &:not(.collapsed) {
        &:after {
            transform: translateY(-50%) rotate(90deg);
        }
    }

    .is-not-touch-device & {
        &:hover {
            color: $success;
        }
    }
}

.accordion-item {
    border: $border-width solid $gray-400;
    border-width: 0 0 $border-width;
    letter-spacing: .03em;

    @include media-breakpoint-up(md) {
        border-width: rem-calc(0 0 2);
        font-size: rem-calc(20);
        line-height: calc(33/20);
    }

    &:first-child {
        border-width: $border-width 0;

        @include media-breakpoint-up(md) {
            border-width: rem-calc(2 0);
        }
    }

    &.active {
        .accordion-button {
            &:after {
                    transform: translateY(-50%) rotate(90deg);
                }
        }
    }
}

.accordion-body {
    padding: rem-calc(0 0 0 50);

    @include media-breakpoint-up(md) {
        padding-left: rem-calc(60);
    }
}