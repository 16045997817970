.posts-holder {
    margin: rem-calc(0 0 40);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(60);
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(80);
    }
}

.post {
    border-bottom: $border-width solid $gray-400;
    font-size: rem-calc(18);
    line-height: calc(38/18);
    padding: rem-calc(0 0 13);
    margin: rem-calc(0 0 30);

    @include media-breakpoint-up(xl) {
        padding-right: rem-calc(20);
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    >* {
        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        margin: 0 0 .5em;
    }

    h4 {
        font-size: rem-calc(22);
        line-height: calc(29/24);
        letter-spacing: 0.035em;
        margin: rem-calc(0 0 25);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(24);
        }

        +.list-info {
            margin-top: rem-calc(-20);
        }
    }

    .list-info {
        font-size: rem-calc(12);
        text-transform: none;
        color: #4A4A4A;
        letter-spacing: 0.02em;
        margin: rem-calc(0 0 25);

        li {
            &:after {
                margin: rem-calc(0 1);
            }
        }
    }

    .btn {
        &.btn-link {
            font-weight: 900;
            font-size: rem-calc(13);
            line-height: calc(18/13);
            color: $cyan-900;

            .bi {
                margin: rem-calc(0 7 0 0);
                color: $success;
                font-size: rem-calc(23);
                transform: scale(-1, 1);
            }
        }
    }
}