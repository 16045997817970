.section-info {
    padding: rem-calc(28 0);

    .simplebar-content-wrapper {
        height: rem-calc(400);
    }

    h2 {
        line-height: calc(34/28);
        font-size: rem-calc(26);
        margin: rem-calc(0 0 27);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(28);
        }
    }

    h3 {
        font-size: rem-calc(22);
        line-height: calc(29/24);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(24);
        }
    }

    .simplebar-content-wrapper {
        padding-left: rem-calc(2);
    }
}

.info-holder {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.info-main {
    padding: rem-calc(0 0 40);

    @include media-breakpoint-up(lg) {
        width: calc(100% - rem-calc(360));
        padding: rem-calc(0 45 0 0);
    }

    @include media-breakpoint-up(xl) {
        padding-right: rem-calc(63);
    }

    &:only-child {
        width: 100%;
        padding: 0;
    }
}

.info-sidebar {
    @include media-breakpoint-up(lg) {
        flex: 1 0;
        padding: rem-calc(20 0 0);
    }


    .btn {
        margin-top: rem-calc(23);
    }
}

.info-items {
    .info-item {
        border-bottom: $border-width solid #D8D8D8;
        margin: rem-calc(0 0 14);
    }

    h5 {
        color: $blue-200;
        font: 500 .875rem/calc(20/14) $font-family-sans-serif;
    }

    .info-time {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font: .8125rem/calc(15/13) $font-family-secondary;
        color: $gray-700;
        text-transform: uppercase;
        letter-spacing: .03em;
        margin: rem-calc(0 0 4);

        span {
            &:after {
                content: '/';
                margin: rem-calc(0 5);
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}