@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: inherit;
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  display: inline-block;
}

iframe {
  max-width: 100%;
}

.resize-active * {
  transition: none !important;
}