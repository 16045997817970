.features-item {
    text-align: center;
    max-width: rem-calc(240);
    margin: 0 auto;
    font-size: rem-calc(18);
    line-height: calc(27/18);
    color: $gray-900;

    >* {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .item-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem-calc(115);
        height: rem-calc(115);
        margin: rem-calc(20) auto rem-calc(40);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}