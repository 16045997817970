.choose-columns {
    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
    }

    >[class*="col"] {
        display: flex;
        flex-direction: column;
        background: $cyan-200;
        padding: rem-calc(35);

        @include media-breakpoint-up(sm) {
            padding: rem-calc(55 77);
        }

        &:nth-child(even) {
            @include media-breakpoint-down(lg) {
                background: $light;
            }
        }

        &:nth-child(4n-2),
        &:nth-child(4n-1) {
            @include media-breakpoint-up(md) {
                background: $light;
            }
        }
    }

    form {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .btn {
        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 300px;
            padding-block: rem-calc(15);

        }
    }

    .col-body {
        flex: 1 0;

        @include media-breakpoint-up(lg) {
            padding-right: rem-calc(15);
        }

        >* {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .col-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: rem-calc(50 -10 -15);

        >* {
            margin: rem-calc(0 10 15);
        }
    }

    .form-select {
        width: rem-calc(275);
        border-radius: $btn-border-radius;
        font-size: rem-calc(18);
        font-weight: 500;
        background-color: $primary;
        border-color: $primary;
        color: $white;
        padding-left: rem-calc(30);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708' fill='%23ffffff'/%3E%3C/svg%3E");
        height: rem-calc(50);

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: rem-calc(300);
        }
    }
}
