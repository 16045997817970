.person-items-holder {
    margin: rem-calc(0 0 40);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(60);
    }

    &:last-child {
        margin-bottom: rem-calc(-20);
    }
}

.person-item {
    display: flex;
    font-size: rem-calc(14);
    line-height: calc(20/14);
    margin: rem-calc(0 0 17);

    @include media-breakpoint-up(md) {
        margin-left: rem-calc(20);
    }

    a {
        color: $blue-200;
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }

    h4 {
        font: 500 1rem/calc(22/16) $font-family-sans-serif;
        color: #4A4A4A;
        margin: 0;
    }

    .person-image {
        width: rem-calc(65);
        height: rem-calc(65);
        margin: rem-calc(0 15 0 0);

        @include media-breakpoint-up(sm) {
            width: rem-calc(75);
            height: rem-calc(75);
        }

        @include media-breakpoint-up(md) {
            margin-right: rem-calc(20);
        }

        img {
            @extend %imgcover;
        }
    }

    .person-text {
        flex: 1 0;
        padding: rem-calc(5 0 0);

        @include media-breakpoint-up(sm) {
            padding-top: rem-calc(8);
        }
    }
}