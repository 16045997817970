#wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: var(--header-height, 0px);
}

main {
    flex: 1 0;
}

.container {
    @include media-breakpoint-up(sm) {
        padding-inline: rem-calc(35)
    }
}

.js-tab-hidden {
    display: block !important;
    left: -9999px !important;
    position: absolute !important;
    top: -9999px !important;
}

.bg-stretch {
    @include absolute;
    z-index: -2;

    img {
        @extend %imgcover;
    }
}

.accessibility {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.accessibility:focus {
    position: static;
    width: auto;
    height: auto;
}

.section {
    margin: rem-calc(50 0);
}

.title-decor {
    position: relative;
    padding: rem-calc(12 0 0);

    @include media-breakpoint-up(md) {
        padding-top: rem-calc(18);
    }

    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: rem-calc(64);
        height: rem-calc(7);
        background: $cyan;

        @include media-breakpoint-up(md) {
            width: rem-calc(74);
        }
    }

    &.decor-sm {
        &:after {
            width: rem-calc(50);
        }
    }

    &.title-decor-center {
        text-align: center;

        &:after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.decor-success {
        &:after {
            background: $success;
        }
    }
}

.video-holder {
    position: relative;
    padding-top: 56%;
    margin: rem-calc(45 0);

    @include media-breakpoint-up(md) {
        margin: rem-calc(60 0);
    }

    @include media-breakpoint-up(lg) {
        margin: rem-calc(77 0);
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem-calc(95);
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    >* {
        @include absolute;
        @extend %imgcover;

    }
}

