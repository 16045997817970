.section-subscribe {
    background: $blue-200;
    color: $white;
    padding: rem-calc(25 0);
    margin: rem-calc(70 0 0);

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(100);
    }

    h2 {
        margin: 0;
        font-size: rem-calc(22);
        line-height: calc(33/27);
        letter-spacing: .05em;
        color: inherit;

        @include media-breakpoint-up(md) {
            font-size: 27px;
        }
    }

    form {
        margin: rem-calc(0 0 -20);

        @include media-breakpoint-up(sm) {
            align-items: center;
            display: flex;
            margin-inline: rem-calc(-10);
        }

        >* {
            margin: rem-calc(0 0 20);

            @include media-breakpoint-up(sm) {
                margin-inline: rem-calc(10);
            }
        }
    }

    .form-control {
        border-radius: $border-radius;
        border-color: $white;
        height: rem-calc(43);
        font-size: rem-calc(16);

        @include media-breakpoint-up(sm) {
            min-width: rem-calc(200);
        }

        @include media-breakpoint-up(md) {
            padding-inline: rem-calc(28);
            min-width: rem-calc(280);
        }

        @include media-breakpoint-up(lg) {
            min-width: rem-calc(320);
        }

        @include placeholder {
            color: #5A5A5A;
        }

        &:focus {
            border-color: $input-focus-border-color;
        }
    }

    .btn {
        min-width: auto;
        font-size: rem-calc(14);
        padding: rem-calc(12 30);
    }
}