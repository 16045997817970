.block-find {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.find-image {
    @include media-breakpoint-up(lg) {
        width: 30%;
    }

    @include media-breakpoint-up(xl) {
        width: 45.5%;
    }

    &:only-child {
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }

    img {
        @extend %imgcover;
    }
}

.find-content {
    position: relative;
    background: $light;
    font-size: rem-calc(16);
    line-height: calc(33/18);
    z-index: 1;
    padding: rem-calc(45 20);

    @include media-breakpoint-up(lg) {
        flex: 1 0;
        font-size: rem-calc(18);
    }

    @include media-breakpoint-up(xl) {
        padding: rem-calc(45 85 45 55);
    }

    &:after {
        @include media-breakpoint-up(lg) {
            position: absolute;
            content: "";
            top: rem-calc(45);
            left: rem-calc(-24);
            z-index: -1;
            width: rem-calc(37);
            height: rem-calc(55);
            background-image: radial-gradient($yellow rem-calc(3), transparent 0);
            background-size: rem-calc(9 9);
        }
    }

    h2 {
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(lg) {
            min-height: rem-calc(50);
            padding-left: rem-calc(48);
        }

        &:after {
            @include media-breakpoint-down(lg) {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                z-index: -1;
                width: rem-calc(30);
                height: rem-calc(50);
                background-image: radial-gradient($yellow rem-calc(2.5), transparent 0);
                background-size: rem-calc(7.5 7.5);

            }
        }
    }

    form {
        margin: rem-calc(20 0 0);

        &:first-child {
            margin-top: 0;
        }

        .btn {
            min-width: auto;
        }
    }

    .form-select,
    .form-control {
        border-color: $white;
        height: rem-calc(51);

        &:focus {
            border-color: $input-focus-border-color;
        }
    }

    .btn {
        padding: rem-calc(13 22);
        font-size: rem-calc(19);

        &.btn-secondary {
            color: $green-900;
        }

        .bi {
            margin: 0;
        }
    }
}