.section-programs {
    padding: rem-calc(13 0);

    @include media-breakpoint-up(md) {
        padding-bottom: rem-calc(25);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(45);
    }

    h2 {
        color: $blue-700;
        font-size: rem-calc(32);
        line-height: calc(36/32);
        margin-bottom: rem-calc(40);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(32);
            line-height: calc(36/32);
            margin-bottom: rem-calc(65);
        }
    }
}