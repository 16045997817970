.main-holder {
    @include media-breakpoint-up(lg) {
        display: flex;
    }

    .breadcrumb {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(-7);
        }
    }

    .saidbar {
        margin: rem-calc(0 0 30);

        @include media-breakpoint-up(lg) {
            width: rem-calc(360);
            margin: rem-calc(0 50 0 0);
        }

        @include media-breakpoint-up(xl) {
            margin-right: rem-calc(85);
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex: 1 0;
        }
    }

    .main-content-inner {
        flex: 1 0;

        >p {
            margin: 0 0 1em;
        }
    }

    h2,
    .h2 {
        @include media-breakpoint-up(md) {
            font-size: rem-calc(33);
            line-height: 1;
        }
    }
}