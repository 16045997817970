.list-info {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    font: 700 .875rem/calc(15/14) $font-family-secondary;
    color: #4A4A4A;
    letter-spacing: .03em;

    li {
        &:last-child {
            &:after {
                display: none;
            }
        }

        &:after {
            content: '/';
            margin: rem-calc(0 5);
        }
    }
}