.simplebar-content-wrapper {
    overflow: auto;
}

.simplebar-track.simplebar-vertical {
    width: rem-calc(14);
    background: $light;
    border: $border-width solid $border-color;

    .simplebar-secondary & {
        width: rem-calc(5);
        background: transparent;
        border: none;
    }
}


.simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
}

.simplebar-scrollbar:before {
    top: rem-calc(3);
    bottom: rem-calc(6);
    left: 0;
    right: 0;
    border-radius: rem-calc(20);
    background: $gray-500;

    .simplebar-secondary & {
        top: rem-calc(0);
        bottom: rem-calc(0);
        border-radius: 0;
        background: $primary;
    }
}

.simplebar-content-wrapper {
    padding: rem-calc(0 12 0 0);

    @include media-breakpoint-up(md) {
        padding-right: rem-calc(17);
    }

    @include media-breakpoint-up(lg) {
        padding-right: rem-calc(22);
    }

    .simplebar-secondary & {
        padding-right: rem-calc(10);
    }
}