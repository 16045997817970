// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------
[id="header"] {
    position: relative;
    background: $white;
    z-index: 999;
    box-shadow: rem-calc(0 4 7) rgba($black, .06);
}

.header-top {
    position: relative;
    background: $primary;
    color: $white;

    @include media-breakpoint-down(lg) {
        z-index: 9999;
    }

    >.container {
        display: flex;
        align-items: center;
    }

    .nav-secondary {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.header-main {
    padding: rem-calc(14 0 23);

    @include media-breakpoint-up(lg) {
        position: relative;
        padding: rem-calc(17 0 21);
    }

    >.container {
        display: flex;
        align-items: center;
    }
}

.nav-secondary {
    @extend %listreset;
    text-transform: uppercase;
    font: 700 .9375rem/calc(17/15) $font-family-secondary;
    letter-spacing: .03em;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex: 1 0;
        padding: rem-calc(12 0);
        margin-inline: rem-calc(-20);
    }

    @include media-breakpoint-up(xl) {
        margin-inline: rem-calc(-24);
    }

    li {
        margin: rem-calc(0 0 4);

        @include media-breakpoint-up(lg) {
            margin-inline: rem-calc(20);
        }

        @include media-breakpoint-up(xl) {
            margin-inline: rem-calc(24);
        }
    }

    a {
        display: inline-block;
        vertical-align: top;
        text-decoration-color: transparent;

        @include media-breakpoint-down(lg) {
            padding: rem-calc(10 0);
        }

        &:hover {
            text-decoration-color: currentColor;
        }
    }
}

.search-wrap {
    z-index: 9999;

    @include media-breakpoint-up(lg) {
        margin: rem-calc(0 0 0 45);
    }

    @include media-breakpoint-up(xl) {
        margin-left: rem-calc(58);
    }

    .search-opener {
        position: relative;
        display: block;
        color: $primary;
        z-index: 1;
        text-decoration: none;
        font-size: rem-calc(25);

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: rem-calc(38);
            right: rem-calc(108);
            z-index: 10;
        }

        @include media-breakpoint-down(sm) {
            top: rem-calc(33);
            right: rem-calc(90);
        }

        @include media-breakpoint-up(lg) {
            color: inherit;
            font-size: rem-calc(22);
        }

        &:hover {
            .bi {
                &.bi-search {
                    color: $cyan-900;
                }
            }
        }

        &[aria-expanded="true"] {
            .bi {
                &.bi-x-lg {
                    @include absolute;
                    opacity: 1;
                }

                &.bi-search {
                    opacity: 0;
                }
            }
        }

        .bi {
            @include animate(opacity color);

            &.bi-x-lg {
                @include absolute;
                opacity: 0;
            }
        }
    }

    .search-close {
        align-self: flex-start;
        margin: rem-calc(5 0 0 5);
        font-size: 0;

        @include media-breakpoint-up(md) {
            margin-top: rem-calc(30);
        }

        &:focus {
            font-size: rem-calc(18);
            outline: none;
        }
    }

    .search-slide {
        @include animate(opacity visible);
        position: absolute;
        display: flex;
        width: 100%;
        align-items: center;
        top: rem-calc(112);
        left: 0;
        transition: none !important;
        opacity: 0;
        visibility: hidden;
        color: $white;
        background: $blue-200;
        padding: rem-calc(40 15 20);

        @include media-breakpoint-up(lg) {
            padding-inline: rem-calc(15);
            top: 100%;
            height: calc(var(--header-main-height) + rem-calc(1));
        }

        &.show {
            opacity: 1;
            z-index: 100;
            visibility: visible;
            transform: translateY(rem-calc(-1));
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        .container {
            display: flex;
            max-width: 855px;
        }
    }
}

.header-search-form {
    width: 100%;
    position: relative;

    .field-wrap {
        margin: rem-calc(0 -7 -15);

        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
        }

        >* {
            margin: rem-calc(0 7 15);
        }
    }

    .btn {
        &:not(.btn-sm) {
            min-width: auto;
        }

        &.btn-primary {
            background: $blue-600;

            &:hover {
                background: darken($blue-600, 10%);
            }
        }

        &.btn-link {
            color: $cyan;
            font-weight: normal;
            font-size: rem-calc(15);
            margin: rem-calc(10 0 0 17);
        }
    }

    input {
        &.form-control {
            width: calc(100% - rem-calc(14));
            border-radius: $btn-border-radius;
            border-color: $white;
            font-size: rem-calc(18);
            letter-spacing: .05em;

            @include media-breakpoint-up(md) {
                padding-inline: rem-calc(30);
            }

            @include placeholder {
                color: $primary;
            }
        }
    }
}

.logo {
    margin: rem-calc(0 20 0 -10);

    @include media-breakpoint-up(lg) {
        margin-left: 0;
    }

    @include media-breakpoint-up(xl) {
        margin-right: rem-calc(75);
    }

    a {
        text-decoration: none;
    }

    img {
        width: rem-calc(200);

        @media (min-width: 479px) {
            width: rem-calc(260);
        }

        @include media-breakpoint-up(xl) {
            width: rem-calc(450);
        }
    }

    .logo-text {
        display: block;
        text-align: center;
        font: 700 .8125rem/1 $font-family-third;
        letter-spacing: .05em;
        color: #5A5A5A;
        border-top: rem-calc(2) solid #D8D8D8;
        padding: rem-calc(9 0 0);
        margin: rem-calc(3 0 0);

        @include media-breakpoint-up(sm) {
            font-size: rem-calc(15);
        }

        @include media-breakpoint-up(xl) {
            font-size: rem-calc(19);
            line-height: calc(23/19);
            margin-top: rem-calc(14);
            padding-top: rem-calc(11);
        }
    }
}

.main-nav {
    flex: 1 0;
}

.nav-drop {
    @include animate(opacity visibility);

    @include media-breakpoint-up(md) {
        padding-inline: 10%;
    }

    @include media-breakpoint-down(lg) {
        position: absolute;
        top: rem-calc(112);
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 999;
        background-color: $cyan-100;
        box-shadow: inset rem-calc(0 4 7) rgba($black, .06);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        flex: 1 0;
        padding: 0;
    }

    .nav-active & {
        opacity: 1;
        visibility: visible;
    }

    .container {
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

    .nav-secondary {
        position: relative;
        z-index: 1;
        color: $white;
        padding: rem-calc(20 34);

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &:after {
            @include media-breakpoint-down(lg) {
                position: absolute;
                content: '';
                background: $primary;
                top: 0;
                bottom: 0;
                left: rem-calc(-9999);
                right: rem-calc(-9999);
                z-index: -1;
            }
        }
    }
}

.nav-menu {
    @extend %listreset;

    @include media-breakpoint-down(lg) {
        padding: rem-calc(19 24);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1 0;
        margin: rem-calc(0 -20);
    }

    @include media-breakpoint-up(xl) {
        margin-inline: rem-calc(-30);
    }
}

.nav-item {
    margin: rem-calc(0 0 15);

    @include media-breakpoint-up(lg) {
        margin: rem-calc(0 20);
    }

    @include media-breakpoint-up(xl) {
        margin-inline: rem-calc(30);
    }

    &.active {
        .nav-link {
            span {
                &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        button.nav-link {
            span {
                position: relative;

                &:after {
                    @include animate(opacity visibility);
                    position: absolute;
                    content: '';
                    height: rem-calc(3);
                    width: rem-calc(35);
                    left: 0;
                    bottom: rem-calc(-3);
                    background: $success;
                    border: none;

                    @include media-breakpoint-up(lg) {
                        width: rem-calc(50);
                        height: rem-calc(4);
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: rem-calc(-8);
                    }
                }
            }
        }
    }
}

.dropdown-holder {
    &.dropdown-active {
        .dropdown-opener {
            &:before {
                opacity: 1;
                visibility: visible;
            }

            &:hover {
                span {
                    &:after {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        .nav-link {
            &:after {
                @include media-breakpoint-down(lg) {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
        }
    }
}

.nav-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: perspective(1px) translateZ(0);
    color: $primary;
    font-size: rem-calc(19);
    line-height: 1;
    font-weight: 700;
    font-family: $font-family-secondary;
    letter-spacing: .08em;

    @include media-breakpoint-down(lg) {
        width: 100%;
        padding: rem-calc(10 50 10 0);
    }

    @include media-breakpoint-up(lg) {
        display: inline-flex;
        align-items: center;
        font-size: rem-calc(16);
        min-height: rem-calc(40);
        letter-spacing: .06em;
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(17);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(20);
    }

    &:not(button) {
        span {
            position: relative;

            &:after {
                @include animate(opacity visibility);
                position: absolute;
                content: '';
                height: rem-calc(3);
                width: rem-calc(35);
                left: 0;
                bottom: rem-calc(-3);
                background: $success;
                opacity: 0;
                visibility: hidden;
                border: none;

                @include media-breakpoint-up(lg) {
                    width: rem-calc(50);
                    height: rem-calc(4);
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: rem-calc(-8);
                }
            }
        }
    }

    &:after {
        @include media-breakpoint-down(lg) {
            @include animate(transform);
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: rem-calc(20);
            height: rem-calc(16);
            width: rem-calc(4);
            background: $success;
        }
    }

    &:before {
        @include media-breakpoint-down(lg) {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: rem-calc(14);
            height: rem-calc(4);
            width: rem-calc(16);
            background: $success;
        }
    }

    &:hover,
    &:focus {
        color: $primary;

        span {
            &:after {
                @include media-breakpoint-up(lg) {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &.dropdown-opener {

        &:before {
            @include media-breakpoint-up(lg) {
                @include animate(opacity visibility);
                position: absolute;
                content: '';
                left: 50%;
                transform: translate(-50%);
                bottom: rem-calc(-42);
                width: 0;
                height: 0;
                border-left: rem-calc(15) solid transparent;
                border-right: rem-calc(15) solid transparent;
                border-bottom: rem-calc(15) solid $cyan-100;
                opacity: 0;
                visibility: hidden;
            }

            @include media-breakpoint-up(xl) {
                bottom: rem-calc(-63);
            }
        }
    }

    br {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.nav-menu-link-holder {
    position: relative;
}

.drop {
    @include media-breakpoint-up(lg) {
        @include animate(visibility opacity transform, .15s);
        display: flex !important;
        justify-content: center;
        position: absolute;
        top: calc(100% - rem-calc(33));
        padding: rem-calc(33 0 0);
        z-index: -1;
    }

    @include media-breakpoint-up(xl) {
        top: calc(100% - rem-calc(60));
        padding: rem-calc(60 0 0);
    }
}

.dropdown-slide {
    border: none;
    border-radius: 0;
    font-size: rem-calc(16);
    line-height: calc(36/18);

    @include media-breakpoint-down(lg) {
        position: relative;
    }

    @include media-breakpoint-up(lg) {
        @include animate(opacity visibility);
        display: block !important;
        position: absolute;
        inset: 0px auto auto 0px;
        top: 100% !important;
        left: 0;
        right: 0;
        width: 100%;
        transform: translate(rem-calc(0), rem-calc(0)) !important;
        opacity: 0;
        visibility: hidden;
        font-size: rem-calc(18);
        letter-spacing: .04em;
        margin-top: rem-calc(-40);
        padding-top: rem-calc(40);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(-60);
        padding-top: rem-calc(60);
    }

    .dropdown-active & {
        @include media-breakpoint-down(lg) {
            position: relative !important;
            inset: auto auto auto auto !important;
            transform: translate3d(0, 0, 0) !important;
        }

        @include media-breakpoint-up(lg) {
            opacity: 1;
            visibility: visible;
        }
    }

    [class^="col"],
    [class*="col"] {
        @include media-breakpoint-up(sm) {
            border-left: $border-width solid $border-color;
        }

        &.order-lg-1 {
            border: none;
        }

        &.order-lg-2 {
            @include media-breakpoint-down(lg) {
                border: none;
            }
        }
    }

    h2 {
        font: 500 1rem/calc(26/22) $headings-font-family;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(22);
        }
    }

    h3 {
        font: 500 1.25rem/calc(26/22) $headings-font-family;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(22);
        }

        @include media-breakpoint-up(lg) {
            margin-block: rem-calc(20 24)
        }
    }

    .btn {
        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(9);
        }
    }

    ul {
        @extend %listreset;
        font-size: rem-calc(15);
        line-height: 1;
        color: $primary;
        margin: rem-calc(0 0 -10);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(17);
        }

        &:first-child {
            @include media-breakpoint-up(lg) {
                margin-top: rem-calc(25);
            }
        }

        li {
            margin: rem-calc(0 0 20);

            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(27);
            }

            &.active {
                a {
                    text-decoration-color: $success;
                }
            }
        }

        a {
            text-decoration-color: transparent;

            &:hover {
                text-decoration-color: $success;
            }
        }
    }
}

.dropdown-slide-inner {
    background: $cyan-100;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(35 0 40);
    }
}

.nav-opener {
    @include animate(transform, .25s);
    display: block;
    position: relative;
    width: rem-calc(37);
    height: rem-calc(37);
    margin: rem-calc(0 12 0) auto;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .nav-active & {
        transform: rotate(90deg);
        margin: rem-calc(6 9) 0 auto;

        &:before,
        &:after {
            transform: rotate(45deg);
            top: 50%;
            left: 0;
            right: 0;
        }

        &:after {
            transform: rotate(-45deg);
        }

        span {
            opacity: 0;
        }
    }

    &:before,
    &:after,
    span {
        position: absolute;
        top: rem-calc(8);
        left: 0;
        right: 0;
        height: rem-calc(6);
        transition: transform .25s linear;
        background: $primary;
    }

    &:before,
    &:after {
        content: '';
        top: rem-calc(18);
    }

    &:after {
        top: rem-calc(28);
    }

    .intro-search-active & {
        @include media-breakpoint-down(lg) {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.resize-active * {
    transition: none !important;
}