.menu-section-box {
    background: $cyan-100;
    border-top: rem-calc(5) solid $cyan-900;
    padding: rem-calc(20 35 45);
    margin: rem-calc(0 0 30);

    @include media-breakpoint-up(sm) {
        padding-inline: rem-calc(57);
        padding-bottom: rem-calc(55);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(40);
        padding-bottom: rem-calc(67);
    }

    &:last-child {
        margin-bottom: 0;
    }


    h4 {
        position: relative;
        z-index: 1;
        color: #5A5A5A;
        font-size: rem-calc(20);
        line-height: calc(23/20);
        padding: rem-calc(0 0 13);
        margin: rem-calc(0 0 24);

        &:after {
            position: absolute;
            width: rem-calc(20);
            height: rem-calc(5);
            content: "";
            bottom: 0;
            left: 0;
            background-image: radial-gradient($cyan-900 rem-calc(1.5), transparent 0);
            background-size: rem-calc(5 5);
            z-index: -1;
        }
    }
}

.menu-section {
    @extend %listreset;
    font-size: rem-calc(17);
    line-height: calc(24/17);
    letter-spacing: .04em;
    color: $info;

    li {
        position: relative;
        margin: rem-calc(0 0 10);

        &:after {
            position: absolute;
            content: '\F285';
            top: rem-calc(3);
            left: rem-calc(-15);
            font-family: bootstrap-icons !important;
            color: $black;
            font-size: rem-calc(12);
            opacity: 0;
            visibility: hidden;
        }

        &.active {
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    a {
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }
}