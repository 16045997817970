.section-intro {
    margin-top: 0;

    h2 {
        position: relative;
        z-index: 1;
        margin: rem-calc(0 0 13);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(20);
        }

        @include media-breakpoint-down(lg) {
            padding-left: rem-calc(48);
            min-height: rem-calc(70);
        }

        &:after {
            @include media-breakpoint-down(lg) {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: rem-calc(30);
                height: rem-calc(60);
                background-image: radial-gradient($cyan rem-calc(2.5), transparent 0);
                background-size: rem-calc(7.5 7.5);
                z-index: -1;
            }
        }
    }

    p {
        margin: rem-calc(0 0 15);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(30);
        }
    }

    .intro-image {
        @include media-breakpoint-up(lg) {
            @include absolute;
            z-index: -2;
        }

        img {
            @extend %imgcover;
        }
    }
}

.intro-holder {
    position: relative;

    @include media-breakpoint-up(lg) {
        min-height: calc(55vw - 70px);
        padding: rem-calc(65 30 65 0);
    }

    @include media-breakpoint-up(xl) {
        min-height: rem-calc(697);
        padding-block: rem-calc(83);
    }

    .controls-holder {
        position: relative;
        width: 100%;
        display: none;
        justify-content: flex-end;
        min-height: rem-calc(18);
        order: 10;
        z-index: 10;

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    .controls {
        position: relative;
        padding: rem-calc(0 40);
        margin: rem-calc(23 0 0);

    }

    .slick-arrow {
        position: absolute;
        top: 0;

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;
        }
    }

    .dots {
        margin-top: rem-calc(3);
    }
}

.gallery-intro-holder {
    position: relative;
    width: calc(100% - rem-calc(24));
    background: rgba($white, .94);
    z-index: 1;
    padding: rem-calc(20 13);

    @include media-breakpoint-up(md) {
        padding: rem-calc(40 30);
    }

    @include media-breakpoint-down(lg) {
        margin: rem-calc(-45) auto 0;
    }

    @include media-breakpoint-up(lg) {
        width: rem-calc(570);
        padding: rem-calc(29 62 22);
    }

    @include media-breakpoint-up(xl) {
        padding-left: rem-calc(77);
    }

    &:after {
        @include media-breakpoint-up(lg) {
            position: absolute;
            content: "";
            top: rem-calc(35);
            left: rem-calc(-24);
            width: rem-calc(45);
            height: rem-calc(73);
            background-image: radial-gradient($cyan rem-calc(2.7), transparent 0);
            background-size: rem-calc(9 9);
            z-index: -1;
        }
    }
}

.gallery-intro {
    display: flex;
    flex-direction: column;

    .slick-list {
        overflow: visible;
    }
}