.info-box {
    position: relative;
    background: $light;
    padding: rem-calc(30 20);
    font-size: rem-calc(15);
    line-height: calc(18/15);
    z-index: 1;

    @include media-breakpoint-up(md) {
        padding-inline: rem-calc(40);
    }

    @include media-breakpoint-up(lg) {
        padding: rem-calc(37 60);
    }

    &:after {
        @include media-breakpoint-up(md) {
            position: absolute;
            content: "";
            top: rem-calc(31);
            left: rem-calc(-21);
            width: rem-calc(45);
            height: rem-calc(72);
            background-image: radial-gradient($cyan rem-calc(2.7), transparent 0);
            background-size: rem-calc(9 9);
            z-index: -1;

            @include media-breakpoint-up(lg) {
                top: rem-calc(71);
            }
        }
    }

    h2 {
        position: relative;
        z-index: 1;
        font-size: rem-calc(27);
        line-height: calc(37/29);
        margin-bottom: rem-calc(16);
        letter-spacing: .04em;

        @include media-breakpoint-down(md) {
            min-height: rem-calc(55);
            padding: rem-calc(0 0 0 50);
        }

        @include media-breakpoint-up(md) {
            font-size: rem-calc(29);
        }

        @include media-breakpoint-up(lg) {
            padding: rem-calc(32 0 0);
        }

        &:after {
            @include media-breakpoint-down(md) {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: rem-calc(35);
                height: rem-calc(55);
                background-image: radial-gradient($cyan rem-calc(2.7), transparent 0);
                background-size: rem-calc(9 9);
                z-index: -1;
            }
        }
    }

    h6,
    .h6 {
        font-size: rem-calc(17);
        color: $gray-900;
        margin: rem-calc(0 0 15);
    }

    a {
        color: $cyan-900;
    }
}

.info-box-holder {
    margin: rem-calc(0 0 30);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(43);
    }

    a {
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }

    p {
        margin: 0 0 1em;
    }

    >* {
        &:last-child {
            margin-bottom: 0;
        }
    }
}