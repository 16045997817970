[id="footer"] {
    background: $primary;
    color: $white;
    padding: rem-calc(45 0 15);

    @include media-breakpoint-up(md) {
        padding-top: 65px;
    }

    h5,
    .h5 {
        color: $cyan;
        margin: rem-calc(0 0 25);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(30);
        }
    }

    a {
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }
}

.copy {
    display: block;
    font-size: rem-calc(12);
    line-height: calc(20/14);
    text-align: center;
    margin: rem-calc(60 0 0);

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(100);
        font-size: rem-calc(14);
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(140);
    }
}

.menu {
    @extend %listreset;
    font-size: rem-calc(16);
    line-height: calc(25/18);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(18);
    }

    li {
        margin: rem-calc(0 0 8);

        &.active {
            a {
                text-decoration-color: currentColor;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.footer-holder {
    text-align: center;
    max-width: rem-calc(500);
    font-size: rem-calc(17);
    line-height: calc(24/17);

    @include media-breakpoint-down(xl) {
        margin-inline: auto;
    }

    .logos-main-holder {
        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(17);
            padding-bottom: rem-calc(17);
        }
    }

    p,
    address {
        margin-bottom: 0;
    }

    a {
        color: $cyan;
    }

    .list-social {
        margin-top: rem-calc(13);

        a {
            color: inherit;
        }


    }
}

.footer-logo {
    margin: rem-calc(0 0 10);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(19);
    }
}