.filters-holder {
    border: rem-calc(1) solid $primary;
    border-width: rem-calc(7 1 1);
    padding: rem-calc(40 25 50);

    form {
        >* {
            &:last-child {
                margin-bottom: 0;
            }
        }

        .jcf-select {
            width: 100%;
        }
    }

    .btn {
        text-transform: none;
        border-radius: 0;
        margin: rem-calc(0 0 23);
        padding-block: rem-calc(12);
        font-size: rem-calc(18);
    }

    .reset-holder {
        .btn {
            margin: 0;
        }
    }

    .form-control,
    .form-select {
        background-color: $cyan-100;
        border-color: $cyan-100;
        margin: rem-calc(0 0 25);
        color: $primary;
        padding-inline: rem-calc(18);
        height: rem-calc(48);
        font-size: rem-calc(18);

        @include placeholder {
            color: $primary;
        }
    }

    .form-select {
        padding-right: rem-calc(40);
        background-position: center right 1rem;
    }

    h5 {
        font-size: rem-calc(15);
        line-height: calc(19/15);
        margin: rem-calc(0 0 5);
    }
}

.filter-search {
    display: flex;

    .form-control {
        flex: 1 0;
    }

    .btn {
        width: rem-calc(43);
        min-width: auto;
        padding: rem-calc(5);
        margin: rem-calc(0 0 26 12);

        .bi {
            margin: 0;
        }
    }
}

.filter-results {
    display: block;
    font-size: rem-calc(20);
    letter-spacing: .03em;
    color: $primary;
    margin: rem-calc(0 0 25);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(23);
        margin-bottom: rem-calc(33);
    }

    strong {
        font-weight: 900;
    }
}

.filter-results-total {
    font-weight: 500;
    line-height: calc(26/19);
    font-size: rem-calc(17);
    letter-spacing: .03em;
    color: $primary;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(19);
    }
}

.filter-dropdown {
    .dropdown-opener {
        &:after {
            display: none;
        }
    }

    .dropdown-menu {
        width: 100%;
        border-radius: 0;
        background: $cyan-100;
        border: none;
        margin: rem-calc(6 0) !important;
        padding: 0;
    }

    .dropdown-inner {
        max-height: rem-calc(300);
        padding: rem-calc(22 16);
    }
}

.checkbox {
    position: relative;
    margin: rem-calc(0 0 15);

    &:last-child {
        margin-bottom: 0;
    }

    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0;

        &:focus {
            +label {
                &:after {
                    background: $primary;
                }
            }
        }

        &:checked {
            +label {
                &:after {
                    background: $primary;
                }
            }
        }

        +label {
            position: relative;
            display: block;
            font-size: rem-calc(16);
            line-height: 1.25;
            letter-spacing: .04em;
            color: $primary;
            padding: rem-calc(0 0 0 32);

            &:after {
                position: absolute;
                content: '';
                left: 0;
                top: rem-calc(2);
                width: rem-calc(14);
                height: rem-calc(14);
                border: $border-width solid $primary;
            }
        }
    }
}

.filters-box {
    background: $cyan-100;
    padding: rem-calc(15 20);
    margin: rem-calc(35 0);

    @include media-breakpoint-up(md) {
        padding: rem-calc(20 27);
        margin: rem-calc(50 0);
    }

    @include media-breakpoint-up(lg) {
        padding: rem-calc(27 32);
        margin: rem-calc(70 0);
    }

    .btn-holder {
        display: flex;
        flex-wrap: wrap;
        margin-inline: rem-calc(-12);

        @include media-breakpoint-up(md) {
            margin-inline: rem-calc(-15);
        }

        @include media-breakpoint-up(lg) {
            margin-inline: rem-calc(-19);
        }

        &:last-child {
            margin-bottom: rem-calc(-15);

            @include media-breakpoint-up(md) {
                margin-bottom: rem-calc(-20);
            }
        }
    }

    .btn {
        font: .9375rem/1.2 $font-family-sans-serif;
        padding: rem-calc(10 25);
        min-width: auto;
        margin: rem-calc(0 12 15);
        text-transform: none;
        letter-spacing: .02em;

        @include media-breakpoint-up(md) {
            margin: rem-calc(0 15 20);
            padding: rem-calc(12 30);
        }

        @include media-breakpoint-up(lg) {
            padding: rem-calc(14 32);
            margin-inline: rem-calc(19);
        }

        .bi {
            margin: rem-calc(0 0 0 17);

            @include media-breakpoint-up(md) {
                margin-left: rem-calc(22);
            }
        }
    }
}