.slick-arrow {
    @include animate(color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    font-size: rem-calc(18);
    background: transparent;
    border: none;
    padding: 0;
    color: $primary;

    .is-not-touch-device & {
    &:hover {
        color: $dark;
    }
    }

    .bi {
        line-height: 1;
    }
}

.slick-dots {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    margin: rem-calc(-5);

    li {
        margin: rem-calc(5);

        &.slick-active {
            button {
                background: $white;
            }
        }
    }

    button {
        @include animate(background);
        width: rem-calc(12);
        height: rem-calc(12);
        border-radius: 50%;
        border: $btn-border-width solid $primary;
        background: $primary;
        padding: 0;

        .is-not-touch-device & {
            &:hover {
                background: $white;
            }
        }
    }
}