.list-social {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    font-size: rem-calc(28);

    li {
        margin: rem-calc(0 6 10);
    }

    a {
        display: inline-block;
        vertical-align: top;
        text-decoration: none;

        &:hover {
            transform: translateY(rem-calc(-5));
        }
    }
}