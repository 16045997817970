.pagination {
    margin: rem-calc(25 -2 -2);
    flex-wrap: wrap;
}

.page-item {

    &.active {
        .page-link {
            background: $cyan-300;
            border: none;
        }
    }

    &:not(:first-child) {
        .page-link {
            @include media-breakpoint-down(md) {
                margin-inline: rem-calc(10);
            }
        }
    }
}

.page-link {
    font-weight: 500;
    font-family: $font-family-secondary;
    display: none;
    align-items: center;
    justify-content: center;
    width: rem-calc(40);
    height: rem-calc(40);
    font-size: rem-calc(14);
    margin: rem-calc(0 10 2);
    color: $primary;

    @include media-breakpoint-up(sm) {
        display: flex;
    }

    @include media-breakpoint-up(md) {
        width: rem-calc(49);
        height: rem-calc(49);
        font-size: rem-calc(15);
        margin: rem-calc(0 2 2);
    }

    &[aria-label="Next"],
    &[aria-label="Previous"] {
        background: $primary;
        color: $white;
        font-size: rem-calc(22);

        @include media-breakpoint-down(sm) {
            display: flex;
        }

        @include media-breakpoint-up(md) {
            font-size: rem-calc(25);
        }

        &:hover {
            background: darken($primary, 10%);
        }
    }
}