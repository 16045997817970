.section-desc {
    margin-bottom: rem-calc(70);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(100);
    }

    &.desc-secondary {
        .desc-holder {
            @include media-breakpoint-up(xl) {
                padding: rem-calc(0 15);
            }
        }

        .desc-image {
            @include media-breakpoint-up(lg) {
                margin-block: rem-calc(21);
            }
        }

        .desc-text {
            @include media-breakpoint-up(lg) {
                margin-block: 0;
            }

            @include media-breakpoint-up(xl) {
                padding-left: rem-calc(92);
            }

            &:after {
                @include media-breakpoint-up(lg) {
                    top: rem-calc(45);
                    left: rem-calc(-20);
                    width: rem-calc(45);
                    height: rem-calc(73);
                    background-image: radial-gradient($cyan rem-calc(2.7), transparent 0);
                    background-size: rem-calc(9 9);
                }
            }
        }
    }

    &.desc-third {
        @include media-breakpoint-up(lg) {
           margin-bottom: rem-calc(90);
        }

        .desc-text {
            @include media-breakpoint-up(xl) {
                padding: rem-calc(53 185 53 60);
            }

            &:after {
                @include media-breakpoint-up(xl) {
                    top: rem-calc(60);
                    width: rem-calc(43);
                    height: rem-calc(73);
                }
            }
        }
    }

    .title-md {
        @include media-breakpoint-up(xl) {
            margin-bottom: rem-calc(50);
        }
    }
}

.desc-holder {
    @include media-breakpoint-up(lg) {
        display: flex;
    }

    h2 {
        @include media-breakpoint-down(lg) {
            position: relative;
            min-height: rem-calc(45);
            z-index: 1;
            padding: rem-calc(0 0 0 50);
        }

        &:after {
            @include media-breakpoint-down(lg) {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: rem-calc(35);
                height: rem-calc(45);
                background-image: radial-gradient($cyan rem-calc(2.7), transparent 0);
                background-size: rem-calc(9 9);
                z-index: -1;
            }
        }
    }
}

.desc-text {
    position: relative;
    z-index: 1;
    background: $cyan-100;
    padding: rem-calc(30 20 65);

    @include media-breakpoint-up(md) {
        padding: rem-calc(50 50 110);
    }

    @include media-breakpoint-up(lg) {
        flex: 1 0;
        margin: rem-calc(21 0);
    }

    @include media-breakpoint-up(lg) {
        padding: rem-calc(30 150 30 45);
    }

    @include media-breakpoint-up(xl) {
        padding: rem-calc(45 250 45 75);
    }

    &:after {
        @include media-breakpoint-up(lg) {
            position: absolute;
            content: "";
            top: rem-calc(55);
            left: rem-calc(-17);
            width: rem-calc(35);
            height: rem-calc(55);
            background-image: radial-gradient($cyan rem-calc(2.7), transparent 0);
            background-size: rem-calc(9 9);
            z-index: -1;
        }
    }

    >* {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .btn {
        margin-top: rem-calc(8);
    }
}

.desc-image {
    position: relative;
    max-width: 90%;
    z-index: 2;
    margin: rem-calc(-30) auto 0;

    @include media-breakpoint-up(md) {
        max-width: 40%;
        margin-top: rem-calc(-50);
    }

    @include media-breakpoint-up(lg) {
        max-width: 60%;
        margin: rem-calc(0 0 0 -110);
        align-self: center;
    }

    img {
        width: 100%;
        object-fit: contain;

        @include media-breakpoint-up(lg) {
            min-height: rem-calc(320);
        }

        @include media-breakpoint-up(xl) {
            min-height: rem-calc(390);
        }
    }
}