.heading-pagination {
    position: relative;
    min-height: rem-calc(50);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: $border-width solid $gray-400;
    border-width: $border-width 0;
    color: $success;
    padding: rem-calc(5 0);
    margin: rem-calc(0 0 25);

        @include media-breakpoint-up(md) {
            min-height: rem-calc(57);
            margin-bottom: rem-calc(38);
    }

    h3 {
        text-transform: uppercase;
        font-size: rem-calc(22);
        line-height: calc(29/24);
        margin: 0;
        color: inherit;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(24);
        }
    }
}

.heading-pagination-arrow {
    @include animate(color);
    width: rem-calc(35);
    height: rem-calc(35);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem-calc(30);
    line-height: 1;
    color: inherit;
    background: transparent;
    border: none;
    margin: rem-calc(2 0 0);

    &:hover {
        color: $primary;
    }

    &.arrow-prev {
        left: 0;
    }

    &.arrow-next {
        right: 0;
    }
}