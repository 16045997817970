@font-face {
    font-family: 'avenir';
    src: url('../fonts/avenirltstd-book-webfont.woff2') format('woff2'),
        url('../fonts/avenirltstd-book-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: url('../fonts/avenirltstd-medium-webfont.woff2') format('woff2'),
        url('../fonts/avenirltstd-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: url('../fonts/avenir-black-webfont.woff2') format('woff2'),
        url('../fonts/avenir-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'avenir-heavy';
    src: url('../fonts/avenirltstd-heavy-webfont.woff2') format('woff2'),
        url('../fonts/avenirltstd-heavy-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'archer';
    src: url('../fonts/archer-bold-pro-webfont.woff2') format('woff2'),
        url('../fonts/archer-bold-pro-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham-book-webfont.woff2') format('woff2'),
        url('../fonts/gotham-book-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham-medium-webfont.woff2') format('woff2'),
        url('../fonts/gotham-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'gotham';
    src: url('../fonts/gotham-bold-webfont.woff2') format('woff2'),
        url('../fonts/gotham-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}