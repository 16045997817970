.btn {
    text-transform: uppercase;

    &:not(.btn-sm) {
        min-width: rem-calc(150);

        @include media-breakpoint-up(md) {
            min-width: rem-calc(196);
            font-size: rem-calc(16);
            padding: rem-calc(14 40);
        }
    }

    &.btn-sm {
        min-width: rem-calc(154);
    }

    &.btn-success {
        color: $white;
    }

    &.btn-info {
        color: $white;
    }

    &.btn-warning {
        color: $white;
    }

    &.btn-link {
        @include animate(text-decoration-color);
        min-width: auto;
        display: inline-flex;
        vertical-align: top;
        align-items: center;
        padding: 0;
        text-decoration-color: transparent;
        font: 500 1rem/calc(24/18) $font-family-sans-serif;
        color: $cyan-900;
        letter-spacing: .01em;
        text-transform: none;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(18);
        }

        &:hover {
            text-decoration-color: currentColor;
        }

        .bi {
            flex-shrink: 0;
            font-size: 0.7em;
            margin: rem-calc(3 -3 0 4);
            line-height: 1;
        }
    }

    &.hide {
        display: none;
    }

    .bi {
        margin: rem-calc(0 8 0 0);

        &.bi-calendar-fill {
            font-size: .75em;
        }
    }
}

.btn-holder {
    display: flex;
    flex-wrap: wrap;
    margin: rem-calc(0 -8);

    &:last-child {
        margin-bottom: rem-calc(-15);

    }

    .btn {
        min-width: rem-calc(174);
        margin: rem-calc(0 8 15);
        padding-inline: rem-calc(24);
        letter-spacing: .04em;

        .bi {
            margin-left: rem-calc(-4);
        }
    }
}