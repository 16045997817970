@charset "UTF-8";
/* ---------------------------------------------------------
  1. Typography
  2. Сommon site styles
  3. Header site styles
  4. Components styles
  5. Footer site styles
  6. Styles for responsive breakpoints
  ---------------------------------------------------------*/
.menu, .list-info, .list-social, .slick-dots, .menu-section, .tabset, .dropdown-slide ul, .nav-menu, .nav-secondary {
  margin: 0;
  padding: 0;
  list-style: none;
}

.section-intro .intro-image img, .find-image img, .card-person .image-card img, .card .card-image img, .person-item .person-image img, .video-holder > *, .bg-stretch img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

/* ---------------------------------------------------------
1. Typography
---------------------------------------------------------*/
body {
  min-width: 375px;
  font-size: 1.125rem;
  line-height: 2;
}
@media (min-width: 576px) {
  body {
    line-height: 1.78;
    font-size: 1.25rem;
  }
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  text-decoration-color: transparent;
}
h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h4 a:hover,
h5 a:hover,
.h5 a:hover,
h6 a:hover,
.h6 a:hover {
  text-decoration-color: currentColor;
}

b,
strong {
  font-weight: 700;
}

h1,
.h1 {
  font-size: 2.18rem;
  margin: 0 0 2.0625rem;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 2.5625rem;
    line-height: 1.2195121951;
    margin-bottom: 1.4375rem;
  }
}

h2,
.h2 {
  font-size: 2rem;
  letter-spacing: 0.03em;
  margin: 0 0 1.875rem;
  margin: 0 0 1.25rem;
}
@media (min-width: 768px) {
  h2,
  .h2 {
    font-size: 2.25rem;
    line-height: 1.1944444444;
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 768px) {
  h2.title-md,
  .h2.title-md {
    font-size: 2.125rem;
    line-height: 1.2058823529;
  }
}

h3,
.h3 {
  font-size: 1.75rem;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  h3,
  .h3 {
    font-size: 1.875rem;
    line-height: 1.2;
    margin-bottom: 1.875rem;
  }
}

h4,
.h4 {
  font-size: 1.625rem;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 1.625rem;
    line-height: 1;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  h5,
  .h5 {
    font-size: 1.375rem;
    line-height: 1.1818181818;
  }
}

h6,
.h6 {
  font-size: 1.25rem;
  letter-spacing: 0.03em;
  line-height: 1.15;
}

a {
  transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out, opacity 0.3s ease-in-out, transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, text-decoration-color 0.3s ease-in-out;
  text-decoration: underline;
  color: inherit;
  text-decoration-color: currentColor;
}
a:hover {
  text-decoration-color: transparent;
  color: inherit;
}

.lead {
  line-height: 1.8947368421;
  letter-spacing: 0.03em;
  margin: 0 0 1.25rem;
}
@media (min-width: 768px) {
  .lead {
    font-size: 1.375rem;
    line-height: 1.6363636364;
  }
}

hr {
  background: #C1CDDB;
}
@media (min-width: 768px) {
  hr {
    height: 0.125rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: inherit;
  }
}
img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  display: inline-block;
}

iframe {
  max-width: 100%;
}

.resize-active * {
  transition: none !important;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenirltstd-book-webfont.woff2") format("woff2"), url("../fonts/avenirltstd-book-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "avenir";
  src: url("../fonts/avenirltstd-medium-webfont.woff2") format("woff2"), url("../fonts/avenirltstd-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir-black-webfont.woff2") format("woff2"), url("../fonts/avenir-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "avenir-heavy";
  src: url("../fonts/avenirltstd-heavy-webfont.woff2") format("woff2"), url("../fonts/avenirltstd-heavy-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "archer";
  src: url("../fonts/archer-bold-pro-webfont.woff2") format("woff2"), url("../fonts/archer-bold-pro-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "gotham";
  src: url("../fonts/gotham-book-webfont.woff2") format("woff2"), url("../fonts/gotham-book-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "gotham";
  src: url("../fonts/gotham-medium-webfont.woff2") format("woff2"), url("../fonts/gotham-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "gotham";
  src: url("../fonts/gotham-bold-webfont.woff2") format("woff2"), url("../fonts/gotham-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
/* Slick slider styles */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s 0.5s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition-delay: 0s;
  transition-duration: 0s;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: scrollbar !important;
}

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.form-select {
  display: flex;
  align-items: center;
}

.form-select,
.form-control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 768px) {
  .form-select,
  .form-control {
    font-size: 1.125rem;
    height: 3.125rem;
  }
}
@media (min-width: 1024px) {
  .form-select,
  .form-control {
    font-size: 1.25rem;
  }
}

.reset-holder input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.reset-holder input:focus + label {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  -webkit-box-shadow: var(--bs-btn-focus-box-shadow);
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.reset-holder input + label {
  cursor: pointer;
  letter-spacing: 0.04em;
}
.reset-holder input + label .bi {
  margin: 0 -0.9375rem 0 0.75rem;
  font-size: 0.9375rem;
  line-height: 1;
}

#wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--header-height, 0px);
}

main {
  flex: 1 0;
}

@media (min-width: 576px) {
  .container {
    padding-inline: 2.1875rem;
  }
}

.js-tab-hidden {
  display: block !important;
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}

.bg-stretch {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
}
.accessibility {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.accessibility:focus {
  position: static;
  width: auto;
  height: auto;
}

.section {
  margin: 3.125rem 0;
}

.title-decor {
  position: relative;
  padding: 0.75rem 0 0;
}
@media (min-width: 768px) {
  .title-decor {
    padding-top: 1.125rem;
  }
}
.title-decor:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 4rem;
  height: 0.4375rem;
  background: #9ddaeb;
}
@media (min-width: 768px) {
  .title-decor:after {
    width: 4.625rem;
  }
}
.title-decor.decor-sm:after {
  width: 3.125rem;
}
.title-decor.title-decor-center {
  text-align: center;
}
.title-decor.title-decor-center:after {
  left: 50%;
  transform: translateX(-50%);
}
.title-decor.decor-success:after {
  background: #00a94f;
}

.video-holder {
  position: relative;
  padding-top: 56%;
  margin: 2.8125rem 0;
}
@media (min-width: 768px) {
  .video-holder {
    margin: 3.75rem 0;
  }
}
@media (min-width: 1024px) {
  .video-holder {
    margin: 4.8125rem 0;
  }
}
@media (min-width: 1470px) {
  .video-holder {
    margin-bottom: 5.9375rem;
  }
}
.video-holder:first-child {
  margin-top: 0;
}
.video-holder:last-child {
  margin-bottom: 0;
}
.video-holder > * {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[id=header] {
  position: relative;
  background: #fff;
  z-index: 999;
  box-shadow: 0 0.25rem 0.4375rem rgba(0, 0, 0, 0.06);
}

.header-top {
  position: relative;
  background: #005ca3;
  color: #fff;
}
@media (max-width: 1023.98px) {
  .header-top {
    z-index: 9999;
  }
}
.header-top > .container {
  display: flex;
  align-items: center;
}
@media (max-width: 1023.98px) {
  .header-top .nav-secondary {
    display: none;
  }
}

.header-main {
  padding: 0.875rem 0 1.4375rem;
}
@media (min-width: 1024px) {
  .header-main {
    position: relative;
    padding: 1.0625rem 0 1.3125rem;
  }
}
.header-main > .container {
  display: flex;
  align-items: center;
}

.nav-secondary {
  text-transform: uppercase;
  font: 700 0.9375rem/1.1333333333 "gotham", "Helvetica Neue", "Arial", sans-serif;
  letter-spacing: 0.03em;
}
@media (min-width: 1024px) {
  .nav-secondary {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex: 1 0;
    padding: 0.75rem 0;
    margin-inline: -1.25rem;
  }
}
@media (min-width: 1470px) {
  .nav-secondary {
    margin-inline: -1.5rem;
  }
}
.nav-secondary li {
  margin: 0 0 0.25rem;
}
@media (min-width: 1024px) {
  .nav-secondary li {
    margin-inline: 1.25rem;
  }
}
@media (min-width: 1470px) {
  .nav-secondary li {
    margin-inline: 1.5rem;
  }
}
.nav-secondary a {
  display: inline-block;
  vertical-align: top;
  text-decoration-color: transparent;
}
@media (max-width: 1023.98px) {
  .nav-secondary a {
    padding: 0.625rem 0;
  }
}
.nav-secondary a:hover {
  text-decoration-color: currentColor;
}

.search-wrap {
  z-index: 9999;
}
@media (min-width: 1024px) {
  .search-wrap {
    margin: 0 0 0 2.8125rem;
  }
}
@media (min-width: 1470px) {
  .search-wrap {
    margin-left: 3.625rem;
  }
}
.search-wrap .search-opener {
  position: relative;
  display: block;
  color: #005ca3;
  z-index: 1;
  text-decoration: none;
  font-size: 1.5625rem;
}
@media (max-width: 1023.98px) {
  .search-wrap .search-opener {
    position: absolute;
    top: 2.375rem;
    right: 6.75rem;
    z-index: 10;
  }
}
@media (max-width: 575.98px) {
  .search-wrap .search-opener {
    top: 2.0625rem;
    right: 5.625rem;
  }
}
@media (min-width: 1024px) {
  .search-wrap .search-opener {
    color: inherit;
    font-size: 1.375rem;
  }
}
.search-wrap .search-opener:hover .bi.bi-search {
  color: #4bbbeb;
}
.search-wrap .search-opener[aria-expanded=true] .bi.bi-x-lg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.search-wrap .search-opener[aria-expanded=true] .bi.bi-search {
  opacity: 0;
}
.search-wrap .search-opener .bi {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}
.search-wrap .search-opener .bi.bi-x-lg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.search-wrap .search-close {
  align-self: flex-start;
  margin: 0.3125rem 0 0 0.3125rem;
  font-size: 0;
}
@media (min-width: 768px) {
  .search-wrap .search-close {
    margin-top: 1.875rem;
  }
}
.search-wrap .search-close:focus {
  font-size: 1.125rem;
  outline: none;
}
.search-wrap .search-slide {
  transition: opacity 0.3s ease-in-out, visible 0.3s ease-in-out;
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  top: 7rem;
  left: 0;
  transition: none !important;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  background: #0082c6;
  padding: 2.5rem 0.9375rem 1.25rem;
}
@media (min-width: 1024px) {
  .search-wrap .search-slide {
    padding-inline: 0.9375rem;
    top: 100%;
    height: calc(var(--header-main-height) + 0.0625rem);
  }
}
.search-wrap .search-slide.show {
  opacity: 1;
  z-index: 100;
  visibility: visible;
  transform: translateY(-0.0625rem);
}
@media (max-width: 575.98px) {
  .search-wrap .search-slide {
    text-align: center;
  }
}
.search-wrap .search-slide .container {
  display: flex;
  max-width: 855px;
}

.header-search-form {
  width: 100%;
  position: relative;
}
.header-search-form .field-wrap {
  margin: 0 -0.4375rem -0.9375rem;
}
@media (min-width: 576px) {
  .header-search-form .field-wrap {
    display: flex;
    align-items: center;
  }
}
.header-search-form .field-wrap > * {
  margin: 0 0.4375rem 0.9375rem;
}
.header-search-form .btn:not(.btn-sm) {
  min-width: auto;
}
.header-search-form .btn.btn-primary {
  background: #035290;
}
.header-search-form .btn.btn-primary:hover {
  background: #02365e;
}
.header-search-form .btn.btn-link {
  color: #9ddaeb;
  font-weight: normal;
  font-size: 0.9375rem;
  margin: 0.625rem 0 0 1.0625rem;
}
.header-search-form input.form-control {
  width: calc(100% - 0.875rem);
  border-radius: 1.56rem;
  border-color: #fff;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .header-search-form input.form-control {
    padding-inline: 1.875rem;
  }
}
.header-search-form input.form-control::-webkit-input-placeholder {
  color: #005ca3;
}
.header-search-form input.form-control::-moz-placeholder {
  opacity: 1;
  color: #005ca3;
}
.header-search-form input.form-control:-moz-placeholder {
  color: #005ca3;
}
.header-search-form input.form-control:-ms-input-placeholder {
  color: #005ca3;
}
.header-search-form input.form-control.placeholder {
  color: #005ca3;
}

.logo {
  margin: 0 1.25rem 0 -0.625rem;
}
@media (min-width: 1024px) {
  .logo {
    margin-left: 0;
  }
}
@media (min-width: 1470px) {
  .logo {
    margin-right: 4.6875rem;
  }
}
.logo a {
  text-decoration: none;
}
.logo img {
  width: 12.5rem;
}
@media (min-width: 479px) {
  .logo img {
    width: 16.25rem;
  }
}
@media (min-width: 1470px) {
  .logo img {
    width: 28.125rem;
  }
}
.logo .logo-text {
  display: block;
  text-align: center;
  font: 700 0.8125rem/1 "archer", "Helvetica Neue", "Arial", sans-serif;
  letter-spacing: 0.05em;
  color: #5A5A5A;
  border-top: 0.125rem solid #D8D8D8;
  padding: 0.5625rem 0 0;
  margin: 0.1875rem 0 0;
}
@media (min-width: 576px) {
  .logo .logo-text {
    font-size: 0.9375rem;
  }
}
@media (min-width: 1470px) {
  .logo .logo-text {
    font-size: 1.1875rem;
    line-height: 1.2105263158;
    margin-top: 0.875rem;
    padding-top: 0.6875rem;
  }
}

.main-nav {
  flex: 1 0;
}

.nav-drop {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .nav-drop {
    padding-inline: 10%;
  }
}
@media (max-width: 1023.98px) {
  .nav-drop {
    position: absolute;
    top: 7rem;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    background-color: #ecf1f8;
    box-shadow: inset 0 0.25rem 0.4375rem rgba(0, 0, 0, 0.06);
  }
}
@media (min-width: 1024px) {
  .nav-drop {
    display: flex;
    align-items: center;
    flex: 1 0;
    padding: 0;
  }
}
.nav-active .nav-drop {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1023.98px) {
  .nav-drop .container {
    padding: 0;
  }
}
.nav-drop .nav-secondary {
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 1.25rem 2.125rem;
}
@media (min-width: 1024px) {
  .nav-drop .nav-secondary {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .nav-drop .nav-secondary:after {
    position: absolute;
    content: "";
    background: #005ca3;
    top: 0;
    bottom: 0;
    left: -624.9375rem;
    right: -624.9375rem;
    z-index: -1;
  }
}

@media (max-width: 1023.98px) {
  .nav-menu {
    padding: 1.1875rem 1.5rem;
  }
}
@media (min-width: 1024px) {
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 0;
    margin: 0 -1.25rem;
  }
}
@media (min-width: 1470px) {
  .nav-menu {
    margin-inline: -1.875rem;
  }
}

.nav-item {
  margin: 0 0 0.9375rem;
}
@media (min-width: 1024px) {
  .nav-item {
    margin: 0 1.25rem;
  }
}
@media (min-width: 1470px) {
  .nav-item {
    margin-inline: 1.875rem;
  }
}
.nav-item.active .nav-link span:after {
  opacity: 1;
  visibility: visible;
}
.nav-item.active button.nav-link span {
  position: relative;
}
.nav-item.active button.nav-link span:after {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  position: absolute;
  content: "";
  height: 0.1875rem;
  width: 2.1875rem;
  left: 0;
  bottom: -0.1875rem;
  background: #00a94f;
  border: none;
}
@media (min-width: 1024px) {
  .nav-item.active button.nav-link span:after {
    width: 3.125rem;
    height: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    bottom: -0.5rem;
  }
}

.dropdown-holder.dropdown-active .dropdown-opener:before {
  opacity: 1;
  visibility: visible;
}
.dropdown-holder.dropdown-active .dropdown-opener:hover span:after {
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1023.98px) {
  .dropdown-holder.dropdown-active .nav-link:after {
    transform: translateY(-50%) rotate(90deg);
  }
}

.nav-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: perspective(1px) translateZ(0);
  color: #005ca3;
  font-size: 1.1875rem;
  line-height: 1;
  font-weight: 700;
  font-family: "gotham", "Helvetica Neue", "Arial", sans-serif;
  letter-spacing: 0.08em;
}
@media (max-width: 1023.98px) {
  .nav-link {
    width: 100%;
    padding: 0.625rem 3.125rem 0.625rem 0;
  }
}
@media (min-width: 1024px) {
  .nav-link {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    min-height: 2.5rem;
    letter-spacing: 0.06em;
  }
}
@media (min-width: 1470px) {
  .nav-link {
    font-size: 1.0625rem;
  }
}
@media (min-width: 1470px) {
  .nav-link {
    font-size: 1.25rem;
  }
}
.nav-link:not(button) span {
  position: relative;
}
.nav-link:not(button) span:after {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  position: absolute;
  content: "";
  height: 0.1875rem;
  width: 2.1875rem;
  left: 0;
  bottom: -0.1875rem;
  background: #00a94f;
  opacity: 0;
  visibility: hidden;
  border: none;
}
@media (min-width: 1024px) {
  .nav-link:not(button) span:after {
    width: 3.125rem;
    height: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    bottom: -0.5rem;
  }
}
@media (max-width: 1023.98px) {
  .nav-link:after {
    transition: transform 0.3s ease-in-out;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.25rem;
    height: 1rem;
    width: 0.25rem;
    background: #00a94f;
  }
}
@media (max-width: 1023.98px) {
  .nav-link:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.875rem;
    height: 0.25rem;
    width: 1rem;
    background: #00a94f;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #005ca3;
}
@media (min-width: 1024px) {
  .nav-link:hover span:after, .nav-link:focus span:after {
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .nav-link.dropdown-opener:before {
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    position: absolute;
    content: "";
    left: 50%;
    transform: translate(-50%);
    bottom: -2.625rem;
    width: 0;
    height: 0;
    border-left: 0.9375rem solid transparent;
    border-right: 0.9375rem solid transparent;
    border-bottom: 0.9375rem solid #ecf1f8;
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 1470px) {
  .nav-link.dropdown-opener:before {
    bottom: -3.9375rem;
  }
}
@media (max-width: 1023.98px) {
  .nav-link br {
    display: none;
  }
}

.nav-menu-link-holder {
  position: relative;
}

@media (min-width: 1024px) {
  .drop {
    transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
    display: flex !important;
    justify-content: center;
    position: absolute;
    top: calc(100% - 2.0625rem);
    padding: 2.0625rem 0 0;
    z-index: -1;
  }
}
@media (min-width: 1470px) {
  .drop {
    top: calc(100% - 3.75rem);
    padding: 3.75rem 0 0;
  }
}

.dropdown-slide {
  border: none;
  border-radius: 0;
  font-size: 1rem;
  line-height: 2;
}
@media (max-width: 1023.98px) {
  .dropdown-slide {
    position: relative;
  }
}
@media (min-width: 1024px) {
  .dropdown-slide {
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    display: block !important;
    position: absolute;
    inset: 0px auto auto 0px;
    top: 100% !important;
    left: 0;
    right: 0;
    width: 100%;
    transform: translate(0, 0) !important;
    opacity: 0;
    visibility: hidden;
    font-size: 1.125rem;
    letter-spacing: 0.04em;
    margin-top: -2.5rem;
    padding-top: 2.5rem;
  }
}
@media (min-width: 1470px) {
  .dropdown-slide {
    margin-top: -3.75rem;
    padding-top: 3.75rem;
  }
}
@media (max-width: 1023.98px) {
  .dropdown-active .dropdown-slide {
    position: relative !important;
    inset: auto auto auto auto !important;
    transform: translate3d(0, 0, 0) !important;
  }
}
@media (min-width: 1024px) {
  .dropdown-active .dropdown-slide {
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .dropdown-slide [class^=col],
  .dropdown-slide [class*=col] {
    border-left: 1px solid #C1CDDB;
  }
}
.dropdown-slide [class^=col].order-lg-1,
.dropdown-slide [class*=col].order-lg-1 {
  border: none;
}
@media (max-width: 1023.98px) {
  .dropdown-slide [class^=col].order-lg-2,
  .dropdown-slide [class*=col].order-lg-2 {
    border: none;
  }
}
.dropdown-slide h2 {
  font: 500 1rem/1.1818181818 "gotham", "Helvetica Neue", "Arial", sans-serif;
}
@media (min-width: 1024px) {
  .dropdown-slide h2 {
    font-size: 1.375rem;
  }
}
.dropdown-slide h3 {
  font: 500 1.25rem/1.1818181818 "gotham", "Helvetica Neue", "Arial", sans-serif;
}
@media (min-width: 1024px) {
  .dropdown-slide h3 {
    font-size: 1.375rem;
  }
}
@media (min-width: 1024px) {
  .dropdown-slide h3 {
    margin-block: 1.25rem 1.5rem;
  }
}
@media (min-width: 1024px) {
  .dropdown-slide .btn {
    margin-top: 0.5625rem;
  }
}
.dropdown-slide ul {
  font-size: 0.9375rem;
  line-height: 1;
  color: #005ca3;
  margin: 0 0 -0.625rem;
}
@media (min-width: 1024px) {
  .dropdown-slide ul {
    font-size: 1.0625rem;
  }
}
@media (min-width: 1024px) {
  .dropdown-slide ul:first-child {
    margin-top: 1.5625rem;
  }
}
.dropdown-slide ul li {
  margin: 0 0 1.25rem;
}
@media (min-width: 1024px) {
  .dropdown-slide ul li {
    margin-bottom: 1.6875rem;
  }
}
.dropdown-slide ul li.active a {
  text-decoration-color: #00a94f;
}
.dropdown-slide ul a {
  text-decoration-color: transparent;
}
.dropdown-slide ul a:hover {
  text-decoration-color: #00a94f;
}

.dropdown-slide-inner {
  background: #ecf1f8;
}
@media (min-width: 1024px) {
  .dropdown-slide-inner {
    padding: 2.1875rem 0 2.5rem;
  }
}

.nav-opener {
  transition: transform 0.25s ease-in-out;
  display: block;
  position: relative;
  width: 2.3125rem;
  height: 2.3125rem;
  margin: 0 0.75rem 0 auto;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .nav-opener {
    display: none;
  }
}
.nav-active .nav-opener {
  transform: rotate(90deg);
  margin: 0.375rem 0.5625rem 0 auto;
}
.nav-active .nav-opener:before, .nav-active .nav-opener:after {
  transform: rotate(45deg);
  top: 50%;
  left: 0;
  right: 0;
}
.nav-active .nav-opener:after {
  transform: rotate(-45deg);
}
.nav-active .nav-opener span {
  opacity: 0;
}
.nav-opener:before, .nav-opener:after,
.nav-opener span {
  position: absolute;
  top: 0.5rem;
  left: 0;
  right: 0;
  height: 0.375rem;
  transition: transform 0.25s linear;
  background: #005ca3;
}
.nav-opener:before, .nav-opener:after {
  content: "";
  top: 1.125rem;
}
.nav-opener:after {
  top: 1.75rem;
}
@media (max-width: 1023.98px) {
  .intro-search-active .nav-opener {
    opacity: 0;
    visibility: hidden;
  }
}

.resize-active * {
  transition: none !important;
}

/* buttons style */
.btn {
  text-transform: uppercase;
}
.btn:not(.btn-sm) {
  min-width: 9.375rem;
}
@media (min-width: 768px) {
  .btn:not(.btn-sm) {
    min-width: 12.25rem;
    font-size: 1rem;
    padding: 0.875rem 2.5rem;
  }
}
.btn.btn-sm {
  min-width: 9.625rem;
}
.btn.btn-success {
  color: #fff;
}
.btn.btn-info {
  color: #fff;
}
.btn.btn-warning {
  color: #fff;
}
.btn.btn-link {
  transition: text-decoration-color 0.3s ease-in-out;
  min-width: auto;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  padding: 0;
  text-decoration-color: transparent;
  font: 500 1rem/1.3333333333 "avenir", "Helvetica Neue", "Arial", sans-serif;
  color: #4bbbeb;
  letter-spacing: 0.01em;
  text-transform: none;
}
@media (min-width: 768px) {
  .btn.btn-link {
    font-size: 1.125rem;
  }
}
.btn.btn-link:hover {
  text-decoration-color: currentColor;
}
.btn.btn-link .bi {
  flex-shrink: 0;
  font-size: 0.7em;
  margin: 0.1875rem -0.1875rem 0 0.25rem;
  line-height: 1;
}
.btn.hide {
  display: none;
}
.btn .bi {
  margin: 0 0.5rem 0 0;
}
.btn .bi.bi-calendar-fill {
  font-size: 0.75em;
}

.btn-holder {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}
.btn-holder:last-child {
  margin-bottom: -0.9375rem;
}
.btn-holder .btn {
  min-width: 10.875rem;
  margin: 0 0.5rem 0.9375rem;
  padding-inline: 1.5rem;
  letter-spacing: 0.04em;
}
.btn-holder .btn .bi {
  margin-left: -0.25rem;
}

.pagination {
  margin: 1.5625rem -0.125rem -0.125rem;
  flex-wrap: wrap;
}

.page-item.active .page-link {
  background: #D5F1F9;
  border: none;
}
@media (max-width: 767.98px) {
  .page-item:not(:first-child) .page-link {
    margin-inline: 0.625rem;
  }
}

.page-link {
  font-weight: 500;
  font-family: "gotham", "Helvetica Neue", "Arial", sans-serif;
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  margin: 0 0.625rem 0.125rem;
  color: #005ca3;
}
@media (min-width: 576px) {
  .page-link {
    display: flex;
  }
}
@media (min-width: 768px) {
  .page-link {
    width: 3.0625rem;
    height: 3.0625rem;
    font-size: 0.9375rem;
    margin: 0 0.125rem 0.125rem;
  }
}
.page-link[aria-label=Next], .page-link[aria-label=Previous] {
  background: #005ca3;
  color: #fff;
  font-size: 1.375rem;
}
@media (max-width: 575.98px) {
  .page-link[aria-label=Next], .page-link[aria-label=Previous] {
    display: flex;
  }
}
@media (min-width: 768px) {
  .page-link[aria-label=Next], .page-link[aria-label=Previous] {
    font-size: 1.5625rem;
  }
}
.page-link[aria-label=Next]:hover, .page-link[aria-label=Previous]:hover {
  background: #003f70;
}

.breadcrumb {
  display: block;
  margin: 0 0 0.625rem;
}

.breadcrumb-item {
  color: #222222;
  display: inline;
  padding: 0;
}
.breadcrumb-item + .breadcrumb-item {
  position: relative;
  padding: 0 0 0 0.75rem;
  margin: 0 0 0 0.25rem;
  letter-spacing: 0.02em;
}
.breadcrumb-item + .breadcrumb-item:before {
  position: absolute;
  top: 0.4375rem;
  left: 0;
  content: "";
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background: #C4BFBF;
  padding: 0;
}
.breadcrumb-item a {
  color: #0082c6;
  text-decoration-color: transparent;
}
.breadcrumb-item a:hover {
  text-decoration-color: currentColor;
}

.accordion {
  margin: 2.1875rem 0;
}
.accordion:first-child {
  margin-top: 0;
}
.accordion:last-child {
  margin-bottom: 0;
}

.accordion-button {
  position: relative;
  font: 700 1.25rem/1.1363636364 "gotham", "Helvetica Neue", "Arial", sans-serif;
  letter-spacing: 0.05em;
  padding: 0.9375rem 0 0.9375rem 3.125rem;
}
@media (min-width: 768px) {
  .accordion-button {
    font-size: 1.375rem;
    padding: 1.5rem 0 1.5rem 3.75rem;
  }
}
.accordion-button:after, .accordion-button:before {
  transition: transform 0.3s ease-in-out;
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 1rem;
  left: 1.25rem;
  background: #00a94f;
  top: 50%;
  transform: translateY(-50%);
}
.accordion-button:after {
  background-image: none;
}
.accordion-button:before {
  height: 0.25rem;
  width: 1rem;
  left: 0.875rem;
}
.accordion-button:not(.collapsed):after {
  transform: translateY(-50%) rotate(90deg);
}
.is-not-touch-device .accordion-button:hover {
  color: #00a94f;
}

.accordion-item {
  border: 1px solid #D8D8D8;
  border-width: 0 0 1px;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  .accordion-item {
    border-width: 0 0 0.125rem;
    font-size: 1.25rem;
    line-height: 1.65;
  }
}
.accordion-item:first-child {
  border-width: 1px 0;
}
@media (min-width: 768px) {
  .accordion-item:first-child {
    border-width: 0.125rem 0;
  }
}
.accordion-item.active .accordion-button:after {
  transform: translateY(-50%) rotate(90deg);
}

.accordion-body {
  padding: 0 0 0 3.125rem;
}
@media (min-width: 768px) {
  .accordion-body {
    padding-left: 3.75rem;
  }
}

.posts-holder {
  margin: 0 0 2.5rem;
}
@media (min-width: 768px) {
  .posts-holder {
    margin-bottom: 3.75rem;
  }
}
@media (min-width: 1024px) {
  .posts-holder {
    margin-bottom: 5rem;
  }
}

.post {
  border-bottom: 1px solid #D8D8D8;
  font-size: 1.125rem;
  line-height: 2.1111111111;
  padding: 0 0 0.8125rem;
  margin: 0 0 1.875rem;
}
@media (min-width: 1470px) {
  .post {
    padding-right: 1.25rem;
  }
}
.post:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.post > *:last-child {
  margin-bottom: 0;
}
.post p {
  margin: 0 0 0.5em;
}
.post h4 {
  font-size: 1.375rem;
  line-height: 1.2083333333;
  letter-spacing: 0.035em;
  margin: 0 0 1.5625rem;
}
@media (min-width: 768px) {
  .post h4 {
    font-size: 1.5rem;
  }
}
.post h4 + .list-info {
  margin-top: -1.25rem;
}
.post .list-info {
  font-size: 0.75rem;
  text-transform: none;
  color: #4A4A4A;
  letter-spacing: 0.02em;
  margin: 0 0 1.5625rem;
}
.post .list-info li:after {
  margin: 0 0.0625rem;
}
.post .btn.btn-link {
  font-weight: 900;
  font-size: 0.8125rem;
  line-height: 1.3846153846;
  color: #4bbbeb;
}
.post .btn.btn-link .bi {
  margin: 0 0.4375rem 0 0;
  color: #00a94f;
  font-size: 1.4375rem;
  transform: scale(-1, 1);
}

.features-item {
  text-align: center;
  max-width: 15rem;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #222222;
}
.features-item > *:last-child {
  margin-bottom: 0;
}
.features-item .item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.1875rem;
  height: 7.1875rem;
  margin: 1.25rem auto 2.5rem;
}
.features-item .item-image:first-child {
  margin-top: 0;
}
.features-item .item-image:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .choose-columns {
    display: flex;
    flex-wrap: wrap;
  }
}
.choose-columns > [class*=col] {
  display: flex;
  flex-direction: column;
  background: #e2f8ff;
  padding: 2.1875rem;
}
@media (min-width: 576px) {
  .choose-columns > [class*=col] {
    padding: 3.4375rem 4.8125rem;
  }
}
@media (max-width: 1023.98px) {
  .choose-columns > [class*=col]:nth-child(even) {
    background: #F4F4F4;
  }
}
@media (min-width: 768px) {
  .choose-columns > [class*=col]:nth-child(4n-2), .choose-columns > [class*=col]:nth-child(4n-1) {
    background: #F4F4F4;
  }
}
@media (max-width: 767.98px) {
  .choose-columns form {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .choose-columns .btn {
    width: 100%;
    max-width: 300px;
    padding-block: 0.9375rem;
  }
}
.choose-columns .col-body {
  flex: 1 0;
}
@media (min-width: 1024px) {
  .choose-columns .col-body {
    padding-right: 0.9375rem;
  }
}
.choose-columns .col-body > *:last-child {
  margin-bottom: 0;
}
.choose-columns .col-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 3.125rem -0.625rem -0.9375rem;
}
.choose-columns .col-footer > * {
  margin: 0 0.625rem 0.9375rem;
}
.choose-columns .form-select {
  width: 17.1875rem;
  border-radius: 1.56rem;
  font-size: 1.125rem;
  font-weight: 500;
  background-color: #005ca3;
  border-color: #005ca3;
  color: #fff;
  padding-left: 1.875rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708' fill='%23ffffff'/%3E%3C/svg%3E");
  height: 3.125rem;
}
@media (max-width: 767.98px) {
  .choose-columns .form-select {
    width: 100%;
    max-width: 18.75rem;
  }
}

.info-box {
  position: relative;
  background: #F4F4F4;
  padding: 1.875rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 1.2;
  z-index: 1;
}
@media (min-width: 768px) {
  .info-box {
    padding-inline: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .info-box {
    padding: 2.3125rem 3.75rem;
  }
}
@media (min-width: 768px) {
  .info-box:after {
    position: absolute;
    content: "";
    top: 1.9375rem;
    left: -1.3125rem;
    width: 2.8125rem;
    height: 4.5rem;
    background-image: radial-gradient(#9ddaeb 0.16875rem, transparent 0);
    background-size: 0.5625rem 0.5625rem;
    z-index: -1;
  }
}
@media (min-width: 768px) and (min-width: 1024px) {
  .info-box:after {
    top: 4.4375rem;
  }
}
.info-box h2 {
  position: relative;
  z-index: 1;
  font-size: 1.6875rem;
  line-height: 1.275862069;
  margin-bottom: 1rem;
  letter-spacing: 0.04em;
}
@media (max-width: 767.98px) {
  .info-box h2 {
    min-height: 3.4375rem;
    padding: 0 0 0 3.125rem;
  }
}
@media (min-width: 768px) {
  .info-box h2 {
    font-size: 1.8125rem;
  }
}
@media (min-width: 1024px) {
  .info-box h2 {
    padding: 2rem 0 0;
  }
}
@media (max-width: 767.98px) {
  .info-box h2:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 2.1875rem;
    height: 3.4375rem;
    background-image: radial-gradient(#9ddaeb 0.16875rem, transparent 0);
    background-size: 0.5625rem 0.5625rem;
    z-index: -1;
  }
}
.info-box h6,
.info-box .h6 {
  font-size: 1.0625rem;
  color: #222222;
  margin: 0 0 0.9375rem;
}
.info-box a {
  color: #4bbbeb;
}

.info-box-holder {
  margin: 0 0 1.875rem;
}
@media (min-width: 768px) {
  .info-box-holder {
    margin-bottom: 2.6875rem;
  }
}
.info-box-holder a {
  text-decoration-color: transparent;
}
.info-box-holder a:hover {
  text-decoration-color: currentColor;
}
.info-box-holder p {
  margin: 0 0 1em;
}
.info-box-holder > *:last-child {
  margin-bottom: 0;
}

.calendar-tabs .filters-holder {
  border-width: 0.3125rem 0.0625rem 0.0625rem;
}

.tabset {
  display: flex;
  border: 0.0625rem solid #005ca3;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0;
}

.tabset-item {
  flex: 1 0;
  text-align: center;
  font: 1rem/1.2222222222 "gotham", "Helvetica Neue", "Arial", sans-serif;
  border-left: 0.0625rem solid #005ca3;
}
@media (min-width: 768px) {
  .tabset-item {
    font-size: 1.125rem;
  }
}

.tabset-button {
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  width: 100%;
  padding: 0.625rem;
  border: 0;
  background: transparent;
  color: #005ca3;
  font-weight: 500;
}
.tabset-button.active {
  background: #005ca3;
  color: #fff;
}
.is-not-touch-device .tabset-button:hover {
  background: #005ca3;
  color: #fff;
}

.filters-holder {
  border: 0.0625rem solid #005ca3;
  border-width: 0.4375rem 0.0625rem 0.0625rem;
  padding: 2.5rem 1.5625rem 3.125rem;
}
.filters-holder form > *:last-child {
  margin-bottom: 0;
}
.filters-holder form .jcf-select {
  width: 100%;
}
.filters-holder .btn {
  text-transform: none;
  border-radius: 0;
  margin: 0 0 1.4375rem;
  padding-block: 0.75rem;
  font-size: 1.125rem;
}
.filters-holder .reset-holder .btn {
  margin: 0;
}
.filters-holder .form-control,
.filters-holder .form-select {
  background-color: #ecf1f8;
  border-color: #ecf1f8;
  margin: 0 0 1.5625rem;
  color: #005ca3;
  padding-inline: 1.125rem;
  height: 3rem;
  font-size: 1.125rem;
}
.filters-holder .form-control::-webkit-input-placeholder,
.filters-holder .form-select::-webkit-input-placeholder {
  color: #005ca3;
}
.filters-holder .form-control::-moz-placeholder,
.filters-holder .form-select::-moz-placeholder {
  opacity: 1;
  color: #005ca3;
}
.filters-holder .form-control:-moz-placeholder,
.filters-holder .form-select:-moz-placeholder {
  color: #005ca3;
}
.filters-holder .form-control:-ms-input-placeholder,
.filters-holder .form-select:-ms-input-placeholder {
  color: #005ca3;
}
.filters-holder .form-control.placeholder,
.filters-holder .form-select.placeholder {
  color: #005ca3;
}
.filters-holder .form-select {
  padding-right: 2.5rem;
  background-position: center right 1rem;
}
.filters-holder h5 {
  font-size: 0.9375rem;
  line-height: 1.2666666667;
  margin: 0 0 0.3125rem;
}

.filter-search {
  display: flex;
}
.filter-search .form-control {
  flex: 1 0;
}
.filter-search .btn {
  width: 2.6875rem;
  min-width: auto;
  padding: 0.3125rem;
  margin: 0 0 1.625rem 0.75rem;
}
.filter-search .btn .bi {
  margin: 0;
}

.filter-results {
  display: block;
  font-size: 1.25rem;
  letter-spacing: 0.03em;
  color: #005ca3;
  margin: 0 0 1.5625rem;
}
@media (min-width: 768px) {
  .filter-results {
    font-size: 1.4375rem;
    margin-bottom: 2.0625rem;
  }
}
.filter-results strong {
  font-weight: 900;
}

.filter-results-total {
  font-weight: 500;
  line-height: 1.3684210526;
  font-size: 1.0625rem;
  letter-spacing: 0.03em;
  color: #005ca3;
}
@media (min-width: 768px) {
  .filter-results-total {
    font-size: 1.1875rem;
  }
}

.filter-dropdown .dropdown-opener:after {
  display: none;
}
.filter-dropdown .dropdown-menu {
  width: 100%;
  border-radius: 0;
  background: #ecf1f8;
  border: none;
  margin: 0.375rem 0 !important;
  padding: 0;
}
.filter-dropdown .dropdown-inner {
  max-height: 18.75rem;
  padding: 1.375rem 1rem;
}

.checkbox {
  position: relative;
  margin: 0 0 0.9375rem;
}
.checkbox:last-child {
  margin-bottom: 0;
}
.checkbox input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.checkbox input[type=checkbox]:focus + label:after {
  background: #005ca3;
}
.checkbox input[type=checkbox]:checked + label:after {
  background: #005ca3;
}
.checkbox input[type=checkbox] + label {
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: 0.04em;
  color: #005ca3;
  padding: 0 0 0 2rem;
}
.checkbox input[type=checkbox] + label:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0.125rem;
  width: 0.875rem;
  height: 0.875rem;
  border: 1px solid #005ca3;
}

.filters-box {
  background: #ecf1f8;
  padding: 0.9375rem 1.25rem;
  margin: 2.1875rem 0;
}
@media (min-width: 768px) {
  .filters-box {
    padding: 1.25rem 1.6875rem;
    margin: 3.125rem 0;
  }
}
@media (min-width: 1024px) {
  .filters-box {
    padding: 1.6875rem 2rem;
    margin: 4.375rem 0;
  }
}
.filters-box .btn-holder {
  display: flex;
  flex-wrap: wrap;
  margin-inline: -0.75rem;
}
@media (min-width: 768px) {
  .filters-box .btn-holder {
    margin-inline: -0.9375rem;
  }
}
@media (min-width: 1024px) {
  .filters-box .btn-holder {
    margin-inline: -1.1875rem;
  }
}
.filters-box .btn-holder:last-child {
  margin-bottom: -0.9375rem;
}
@media (min-width: 768px) {
  .filters-box .btn-holder:last-child {
    margin-bottom: -1.25rem;
  }
}
.filters-box .btn {
  font: 0.9375rem/1.2 "avenir", "Helvetica Neue", "Arial", sans-serif;
  padding: 0.625rem 1.5625rem;
  min-width: auto;
  margin: 0 0.75rem 0.9375rem;
  text-transform: none;
  letter-spacing: 0.02em;
}
@media (min-width: 768px) {
  .filters-box .btn {
    margin: 0 0.9375rem 1.25rem;
    padding: 0.75rem 1.875rem;
  }
}
@media (min-width: 1024px) {
  .filters-box .btn {
    padding: 0.875rem 2rem;
    margin-inline: 1.1875rem;
  }
}
.filters-box .btn .bi {
  margin: 0 0 0 1.0625rem;
}
@media (min-width: 768px) {
  .filters-box .btn .bi {
    margin-left: 1.375rem;
  }
}

@media (min-width: 1024px) {
  .main-holder {
    display: flex;
  }
}
.main-holder .breadcrumb {
  margin: 0 0 1.25rem;
}
@media (min-width: 1024px) {
  .main-holder .breadcrumb {
    margin-top: -0.4375rem;
  }
}
.main-holder .saidbar {
  margin: 0 0 1.875rem;
}
@media (min-width: 1024px) {
  .main-holder .saidbar {
    width: 22.5rem;
    margin: 0 3.125rem 0 0;
  }
}
@media (min-width: 1470px) {
  .main-holder .saidbar {
    margin-right: 5.3125rem;
  }
}
.main-holder .main-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .main-holder .main-content {
    flex: 1 0;
  }
}
.main-holder .main-content-inner {
  flex: 1 0;
}
.main-holder .main-content-inner > p {
  margin: 0 0 1em;
}
@media (min-width: 768px) {
  .main-holder h2,
  .main-holder .h2 {
    font-size: 2.0625rem;
    line-height: 1;
  }
}

.heading-pagination {
  position: relative;
  min-height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #D8D8D8;
  border-width: 1px 0;
  color: #00a94f;
  padding: 0.3125rem 0;
  margin: 0 0 1.5625rem;
}
@media (min-width: 768px) {
  .heading-pagination {
    min-height: 3.5625rem;
    margin-bottom: 2.375rem;
  }
}
.heading-pagination h3 {
  text-transform: uppercase;
  font-size: 1.375rem;
  line-height: 1.2083333333;
  margin: 0;
  color: inherit;
}
@media (min-width: 768px) {
  .heading-pagination h3 {
    font-size: 1.5rem;
  }
}

.heading-pagination-arrow {
  transition: color 0.3s ease-in-out;
  width: 2.1875rem;
  height: 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.875rem;
  line-height: 1;
  color: inherit;
  background: transparent;
  border: none;
  margin: 0.125rem 0 0;
}
.heading-pagination-arrow:hover {
  color: #005ca3;
}
.heading-pagination-arrow.arrow-prev {
  left: 0;
}
.heading-pagination-arrow.arrow-next {
  right: 0;
}

.events-info {
  margin: 0 0 1.875rem;
}
@media (min-width: 768px) {
  .events-info {
    margin-bottom: 2.5rem;
  }
}
.events-info:last-child {
  margin-bottom: 0;
}
.events-info .simplebar-content-wrapper {
  max-height: 26.5625rem;
}
@media (min-width: 768px) {
  .events-info .simplebar-content-wrapper {
    padding: 0 0.625rem 0 0;
  }
}
.events-info .simplebar-content-wrapper.simplebar-secondary .simplebar-scrollbar:before {
  background: #0082c6;
}
.events-info .info-items {
  padding: 1.5625rem 0.625rem 0.625rem 1.3125rem;
}

.events-info-title {
  display: flex;
  background: #F4F4F4;
  padding: 0.625rem 1.25rem;
}
@media (min-width: 768px) {
  .events-info-title {
    padding-inline: 2.5rem;
  }
}
.events-info-title h4,
.events-info-title .h4 {
  font-size: 1.125rem;
  line-height: 1.15;
  color: #0082c6;
  margin: 0;
}
@media (min-width: 768px) {
  .events-info-title h4,
  .events-info-title .h4 {
    font-size: 1.25rem;
  }
}
.events-info-title .bi {
  font-size: 1.0625rem;
  line-height: 1;
  margin: 0 0.8125rem 0 0;
  color: #4bbbeb;
}
@media (min-width: 768px) {
  .events-info-title .bi {
    font-size: 1.1875rem;
  }
}

.menu-section-box {
  background: #ecf1f8;
  border-top: 0.3125rem solid #4bbbeb;
  padding: 1.25rem 2.1875rem 2.8125rem;
  margin: 0 0 1.875rem;
}
@media (min-width: 576px) {
  .menu-section-box {
    padding-inline: 3.5625rem;
    padding-bottom: 3.4375rem;
  }
}
@media (min-width: 768px) {
  .menu-section-box {
    margin-bottom: 2.5rem;
    padding-bottom: 4.1875rem;
  }
}
.menu-section-box:last-child {
  margin-bottom: 0;
}
.menu-section-box h4 {
  position: relative;
  z-index: 1;
  color: #5A5A5A;
  font-size: 1.25rem;
  line-height: 1.15;
  padding: 0 0 0.8125rem;
  margin: 0 0 1.5rem;
}
.menu-section-box h4:after {
  position: absolute;
  width: 1.25rem;
  height: 0.3125rem;
  content: "";
  bottom: 0;
  left: 0;
  background-image: radial-gradient(#4bbbeb 0.09375rem, transparent 0);
  background-size: 0.3125rem 0.3125rem;
  z-index: -1;
}

.menu-section {
  font-size: 1.0625rem;
  line-height: 1.4117647059;
  letter-spacing: 0.04em;
  color: #0082c6;
}
.menu-section li {
  position: relative;
  margin: 0 0 0.625rem;
}
.menu-section li:after {
  position: absolute;
  content: "\f285";
  top: 0.1875rem;
  left: -0.9375rem;
  font-family: bootstrap-icons !important;
  color: #000;
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
}
.menu-section li.active:after {
  opacity: 1;
  visibility: visible;
}
.menu-section a {
  text-decoration-color: transparent;
}
.menu-section a:hover {
  text-decoration-color: currentColor;
}

.person-items-holder {
  margin: 0 0 2.5rem;
}
@media (min-width: 768px) {
  .person-items-holder {
    margin-bottom: 3.75rem;
  }
}
.person-items-holder:last-child {
  margin-bottom: -1.25rem;
}

.person-item {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin: 0 0 1.0625rem;
}
@media (min-width: 768px) {
  .person-item {
    margin-left: 1.25rem;
  }
}
.person-item a {
  color: #0082c6;
  text-decoration-color: transparent;
}
.person-item a:hover {
  text-decoration-color: currentColor;
}
.person-item h4 {
  font: 500 1rem/1.375 "avenir", "Helvetica Neue", "Arial", sans-serif;
  color: #4A4A4A;
  margin: 0;
}
.person-item .person-image {
  width: 4.0625rem;
  height: 4.0625rem;
  margin: 0 0.9375rem 0 0;
}
@media (min-width: 576px) {
  .person-item .person-image {
    width: 4.6875rem;
    height: 4.6875rem;
  }
}
@media (min-width: 768px) {
  .person-item .person-image {
    margin-right: 1.25rem;
  }
}
.person-item .person-text {
  flex: 1 0;
  padding: 0.3125rem 0 0;
}
@media (min-width: 576px) {
  .person-item .person-text {
    padding-top: 0.5rem;
  }
}

.slick-arrow {
  transition: color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  font-size: 1.125rem;
  background: transparent;
  border: none;
  padding: 0;
  color: #005ca3;
}
.is-not-touch-device .slick-arrow:hover {
  color: #222222;
}
.slick-arrow .bi {
  line-height: 1;
}

.slick-dots {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  margin: -0.3125rem;
}
.slick-dots li {
  margin: 0.3125rem;
}
.slick-dots li.slick-active button {
  background: #fff;
}
.slick-dots button {
  transition: background 0.3s ease-in-out;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 1px solid #005ca3;
  background: #005ca3;
  padding: 0;
}
.is-not-touch-device .slick-dots button:hover {
  background: #fff;
}

.simplebar-content-wrapper {
  overflow: auto;
}

.simplebar-track.simplebar-vertical {
  width: 0.875rem;
  background: #F4F4F4;
  border: 1px solid #C1CDDB;
}
.simplebar-secondary .simplebar-track.simplebar-vertical {
  width: 0.3125rem;
  background: transparent;
  border: none;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.simplebar-scrollbar:before {
  top: 0.1875rem;
  bottom: 0.375rem;
  left: 0;
  right: 0;
  border-radius: 1.25rem;
  background: #C1CDDB;
}
.simplebar-secondary .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
  border-radius: 0;
  background: #005ca3;
}

.simplebar-content-wrapper {
  padding: 0 0.75rem 0 0;
}
@media (min-width: 768px) {
  .simplebar-content-wrapper {
    padding-right: 1.0625rem;
  }
}
@media (min-width: 1024px) {
  .simplebar-content-wrapper {
    padding-right: 1.375rem;
  }
}
.simplebar-secondary .simplebar-content-wrapper {
  padding-right: 0.625rem;
}

.gallery-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.9375rem;
}
@media (min-width: 768px) {
  .gallery-cards {
    margin-inline: -1.25rem;
  }
}
.gallery-cards .slick-arrow {
  transition: background 0.3s ease-in-out;
  width: 2.5rem;
  height: 2.3125rem;
  order: 10;
  background: #ACB7C4;
  color: #fff;
}
@media (min-width: 768px) {
  .gallery-cards .slick-arrow {
    width: 3.125rem;
    height: 2.9375rem;
  }
}
.is-not-touch-device .gallery-cards .slick-arrow:hover {
  color: #fff;
  background: #4bbbeb;
}
.gallery-cards .slick-arrow.slick-prev {
  margin: 1.875rem 0.25rem 0 auto;
  border-radius: 1.875rem 0 0 1.875rem;
}
.gallery-cards .slick-arrow.slick-next {
  margin: 1.875rem auto 0 0.25rem;
  border-radius: 0 1.875rem 1.875rem 0;
}
.gallery-cards .slick-list,
.gallery-cards .slick-track {
  display: flex;
}
.gallery-cards .slick-slide {
  display: flex;
  margin: 0 0.9375rem;
}
@media (min-width: 768px) {
  .gallery-cards .slick-slide {
    margin-inline: 1.25rem;
  }
}

.list-social {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.75rem;
}
.list-social li {
  margin: 0 0.375rem 0.625rem;
}
.list-social a {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}
.list-social a:hover {
  transform: translateY(-0.3125rem);
}

.list-info {
  display: flex;
  flex-wrap: wrap;
  font: 700 0.875rem/1.0714285714 "gotham", "Helvetica Neue", "Arial", sans-serif;
  color: #4A4A4A;
  letter-spacing: 0.03em;
}
.list-info li:last-child:after {
  display: none;
}
.list-info li:after {
  content: "/";
  margin: 0 0.3125rem;
}

.card {
  width: 100%;
  font-size: 0.9375rem;
  line-height: 1.5333333333;
  letter-spacing: 0.02em;
  color: #222222;
}
.card .card-image {
  position: relative;
  display: block;
  padding-top: 61%;
  overflow: hidden;
}
.card .card-image img {
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.card a.card-image:hover img {
  transform: scale(1.1);
}
.card p {
  margin: 0 0 1em;
}
.card h4,
.card .h4 {
  font-size: 1.0625rem;
  line-height: 1.2941176471;
  margin: 0 0 0.3125rem;
}
.card .card-body {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1470px) {
  .card .card-body {
    padding-right: 1.875rem;
  }
}
.card .card-body:last-child {
  padding-bottom: 2.5rem;
}
.card .card-body p:last-child {
  margin-bottom: 0;
}
.card .card-title {
  margin: 0 0 1.625rem;
}
.card .card-title > *:last-child {
  margin-bottom: 0;
}
.card time {
  display: block;
  font: 700 0.8125rem/0.9230769231 "gotham", "Helvetica Neue", "Arial", sans-serif;
  color: #444444;
}

.cards-horizontal-holder:last-child {
  margin-bottom: -1.875rem;
}
@media (min-width: 768px) {
  .cards-horizontal-holder:last-child {
    margin-bottom: -2.625rem;
  }
}
.cards-horizontal-holder .card-horizontal {
  margin: 0 0 1.875rem;
}
@media (min-width: 768px) {
  .cards-horizontal-holder .card-horizontal {
    margin-bottom: 2.625rem;
  }
}

.card-horizontal {
  color: #333333;
}
@media (min-width: 768px) {
  .card-horizontal {
    flex-direction: row;
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 768px) {
  .card-horizontal .card-image {
    width: 21.5625rem;
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .card-horizontal .card-image img {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
}
@media (min-width: 768px) {
  .card-horizontal .card-body {
    flex: 1 0;
    padding: 0.9375rem 2.25rem;
  }
}
@media (min-width: 768px) {
  .card-horizontal .card-body:last-child {
    padding-bottom: 0.625rem;
  }
}
.card-horizontal .card-title {
  position: relative;
  z-index: 1;
  padding: 0 0 0.9375rem 0.375rem;
  margin: 0 0 1.5625rem;
}
.card-horizontal .card-title:after {
  position: absolute;
  width: 1.25rem;
  height: 0.3125rem;
  content: "";
  bottom: 0;
  left: 0.375rem;
  background-image: radial-gradient(#4bbbeb 0.09375rem, transparent 0);
  background-size: 0.3125rem 0.3125rem;
  z-index: -1;
}
.card-horizontal h6,
.card-horizontal .h6 {
  color: #0082c6;
}
.card-horizontal .btn.btn-link {
  color: #4bbbeb;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  font-weight: 900;
  font-family: "avenir-heavy", "Helvetica Neue", "Arial", sans-serif;
}

.cards-program-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1.25rem;
}
@media (min-width: 768px) {
  .cards-program-holder {
    margin-inline: -2.5rem;
  }
}
@media (min-width: 1024px) {
  .cards-program-holder {
    margin-inline: -3.1875rem;
  }
}
.cards-program-holder:last-child {
  margin-bottom: -3.125rem;
}
@media (min-width: 768px) {
  .cards-program-holder:last-child {
    margin-bottom: -4.875rem;
  }
}
.cards-program-holder .card-program {
  margin: 0 1.25rem 3.125rem;
}
@media (min-width: 768px) {
  .cards-program-holder .card-program {
    margin: 0 2.5rem 4.875rem;
  }
}
@media (min-width: 1024px) {
  .cards-program-holder .card-program {
    margin-inline: 3.1875rem;
  }
}

.card-program {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 18.75rem;
  text-align: center;
  background: #F4F4F4;
  border-top: 2.0625rem solid #9ddaeb;
  font-size: 0.9375rem;
  line-height: 1.8;
}
.card-program .card-head,
.card-program .card-body,
.card-program .card-footer {
  padding-inline: 1.4375rem;
}
.card-program .card-head {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 5.625rem;
  padding-block: 0.625rem;
}
.card-program .card-head:after {
  position: absolute;
  content: "";
  height: 0.0625rem;
  left: 2.0625rem;
  right: 2.0625rem;
  bottom: 0;
  background: #D8D8D8;
}
.card-program .card-head > *:last-child {
  margin-bottom: 0;
}
.card-program .card-body {
  flex: 1 0;
  padding-block: 1.4375rem;
}
.card-program .card-body > *:last-child {
  margin-bottom: 0;
}
.card-program .card-footer {
  padding: 0.4375rem 1.5rem 1.5rem;
}
.card-program h3 {
  color: #0082c6;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-person {
  width: 100%;
  border-bottom: 1px solid #D8D8D8;
  margin: 0 0 1.625rem;
  padding: 0 0 1.75rem;
  font-size: 1rem;
  line-height: 1.55;
  letter-spacing: 0.04em;
}
@media (min-width: 768px) {
  .card-person {
    display: flex;
  }
}
.card-person:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.card-person a {
  color: #4bbbeb;
}
.card-person h3 {
  font-size: 1.25rem;
  line-height: 1.1818181818;
  margin: 0 0 1.375rem;
}
@media (min-width: 768px) {
  .card-person h3 {
    font-size: 1.375rem;
  }
}
.card-person .image-card {
  width: 10.625rem;
  height: 10.625rem;
  margin: 0 0 1.875rem;
}
@media (min-width: 768px) {
  .card-person .image-card {
    width: 9.375rem;
    height: 9.375rem;
    margin: 0 2.5rem 0 0;
  }
}
@media (min-width: 1024px) {
  .card-person .image-card {
    margin-right: 3.75rem;
  }
}
@media (min-width: 1470px) {
  .card-person .image-card {
    margin-right: 4.375rem;
  }
}
@media (min-width: 768px) {
  .card-person .card-boby {
    flex: 1 0;
  }
}
.card-person p {
  margin: 0;
}
.card-person a {
  text-decoration-color: transparent;
}
.card-person a:hover {
  text-decoration-color: currentColor;
}

@media (min-width: 1024px) {
  .block-find {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .find-image {
    width: 30%;
  }
}
@media (min-width: 1470px) {
  .find-image {
    width: 45.5%;
  }
}
@media (min-width: 1024px) {
  .find-image:only-child {
    width: 100%;
  }
}
.find-content {
  position: relative;
  background: #F4F4F4;
  font-size: 1rem;
  line-height: 1.8333333333;
  z-index: 1;
  padding: 2.8125rem 1.25rem;
}
@media (min-width: 1024px) {
  .find-content {
    flex: 1 0;
    font-size: 1.125rem;
  }
}
@media (min-width: 1470px) {
  .find-content {
    padding: 2.8125rem 5.3125rem 2.8125rem 3.4375rem;
  }
}
@media (min-width: 1024px) {
  .find-content:after {
    position: absolute;
    content: "";
    top: 2.8125rem;
    left: -1.5rem;
    z-index: -1;
    width: 2.3125rem;
    height: 3.4375rem;
    background-image: radial-gradient(#e0e56d 0.1875rem, transparent 0);
    background-size: 0.5625rem 0.5625rem;
  }
}
.find-content h2 {
  position: relative;
  z-index: 1;
}
@media (max-width: 1023.98px) {
  .find-content h2 {
    min-height: 3.125rem;
    padding-left: 3rem;
  }
}
@media (max-width: 1023.98px) {
  .find-content h2:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    width: 1.875rem;
    height: 3.125rem;
    background-image: radial-gradient(#e0e56d 0.15625rem, transparent 0);
    background-size: 0.46875rem 0.46875rem;
  }
}
.find-content form {
  margin: 1.25rem 0 0;
}
.find-content form:first-child {
  margin-top: 0;
}
.find-content form .btn {
  min-width: auto;
}
.find-content .form-select,
.find-content .form-control {
  border-color: #fff;
  height: 3.1875rem;
}
.find-content .form-select:focus,
.find-content .form-control:focus {
  border-color: #005ca3;
}
.find-content .btn {
  padding: 0.8125rem 1.375rem;
  font-size: 1.1875rem;
}
.find-content .btn.btn-secondary {
  color: #285845;
}
.find-content .btn .bi {
  margin: 0;
}

.section-intro {
  margin-top: 0;
}
.section-intro h2 {
  position: relative;
  z-index: 1;
  margin: 0 0 0.8125rem;
}
@media (min-width: 768px) {
  .section-intro h2 {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 1023.98px) {
  .section-intro h2 {
    padding-left: 3rem;
    min-height: 4.375rem;
  }
}
@media (max-width: 1023.98px) {
  .section-intro h2:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 1.875rem;
    height: 3.75rem;
    background-image: radial-gradient(#9ddaeb 0.15625rem, transparent 0);
    background-size: 0.46875rem 0.46875rem;
    z-index: -1;
  }
}
.section-intro p {
  margin: 0 0 0.9375rem;
}
@media (min-width: 1024px) {
  .section-intro p {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 1024px) {
  .section-intro .intro-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
  }
}
.intro-holder {
  position: relative;
}
@media (min-width: 1024px) {
  .intro-holder {
    min-height: calc(55vw - 70px);
    padding: 4.0625rem 1.875rem 4.0625rem 0;
  }
}
@media (min-width: 1470px) {
  .intro-holder {
    min-height: 43.5625rem;
    padding-block: 5.1875rem;
  }
}
.intro-holder .controls-holder {
  position: relative;
  width: 100%;
  display: none;
  justify-content: flex-end;
  min-height: 1.125rem;
  order: 10;
  z-index: 10;
}
@media (min-width: 1024px) {
  .intro-holder .controls-holder {
    display: flex;
  }
}
.intro-holder .controls {
  position: relative;
  padding: 0 2.5rem;
  margin: 1.4375rem 0 0;
}
.intro-holder .slick-arrow {
  position: absolute;
  top: 0;
}
.intro-holder .slick-arrow.slick-prev {
  left: 0;
}
.intro-holder .slick-arrow.slick-next {
  right: 0;
}
.intro-holder .dots {
  margin-top: 0.1875rem;
}

.gallery-intro-holder {
  position: relative;
  width: calc(100% - 1.5rem);
  background: rgba(255, 255, 255, 0.94);
  z-index: 1;
  padding: 1.25rem 0.8125rem;
}
@media (min-width: 768px) {
  .gallery-intro-holder {
    padding: 2.5rem 1.875rem;
  }
}
@media (max-width: 1023.98px) {
  .gallery-intro-holder {
    margin: -2.8125rem auto 0;
  }
}
@media (min-width: 1024px) {
  .gallery-intro-holder {
    width: 35.625rem;
    padding: 1.8125rem 3.875rem 1.375rem;
  }
}
@media (min-width: 1470px) {
  .gallery-intro-holder {
    padding-left: 4.8125rem;
  }
}
@media (min-width: 1024px) {
  .gallery-intro-holder:after {
    position: absolute;
    content: "";
    top: 2.1875rem;
    left: -1.5rem;
    width: 2.8125rem;
    height: 4.5625rem;
    background-image: radial-gradient(#9ddaeb 0.16875rem, transparent 0);
    background-size: 0.5625rem 0.5625rem;
    z-index: -1;
  }
}

.gallery-intro {
  display: flex;
  flex-direction: column;
}
.gallery-intro .slick-list {
  overflow: visible;
}

.section-info {
  padding: 1.75rem 0;
}
.section-info .simplebar-content-wrapper {
  height: 25rem;
}
.section-info h2 {
  line-height: 1.2142857143;
  font-size: 1.625rem;
  margin: 0 0 1.6875rem;
}
@media (min-width: 768px) {
  .section-info h2 {
    font-size: 1.75rem;
  }
}
.section-info h3 {
  font-size: 1.375rem;
  line-height: 1.2083333333;
}
@media (min-width: 768px) {
  .section-info h3 {
    font-size: 1.5rem;
  }
}
.section-info .simplebar-content-wrapper {
  padding-left: 0.125rem;
}

@media (min-width: 1024px) {
  .info-holder {
    display: flex;
  }
}

.info-main {
  padding: 0 0 2.5rem;
}
@media (min-width: 1024px) {
  .info-main {
    width: calc(100% - 22.5rem);
    padding: 0 2.8125rem 0 0;
  }
}
@media (min-width: 1470px) {
  .info-main {
    padding-right: 3.9375rem;
  }
}
.info-main:only-child {
  width: 100%;
  padding: 0;
}

@media (min-width: 1024px) {
  .info-sidebar {
    flex: 1 0;
    padding: 1.25rem 0 0;
  }
}
.info-sidebar .btn {
  margin-top: 1.4375rem;
}

.info-items .info-item {
  border-bottom: 1px solid #D8D8D8;
  margin: 0 0 0.875rem;
}
.info-items h5 {
  color: #0082c6;
  font: 500 0.875rem/1.4285714286 "avenir", "Helvetica Neue", "Arial", sans-serif;
}
.info-items .info-time {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font: 0.8125rem/1.1538461538 "gotham", "Helvetica Neue", "Arial", sans-serif;
  color: #444444;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin: 0 0 0.25rem;
}
.info-items .info-time span:after {
  content: "/";
  margin: 0 0.3125rem;
}
.info-items .info-time span:last-child:after {
  display: none;
}

.section-subscribe {
  background: #0082c6;
  color: #fff;
  padding: 1.5625rem 0;
  margin: 4.375rem 0 0;
}
@media (max-width: 575.98px) {
  .section-subscribe {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .section-subscribe {
    margin-top: 6.25rem;
  }
}
.section-subscribe h2 {
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.2222222222;
  letter-spacing: 0.05em;
  color: inherit;
}
@media (min-width: 768px) {
  .section-subscribe h2 {
    font-size: 27px;
  }
}
.section-subscribe form {
  margin: 0 0 -1.25rem;
}
@media (min-width: 576px) {
  .section-subscribe form {
    align-items: center;
    display: flex;
    margin-inline: -0.625rem;
  }
}
.section-subscribe form > * {
  margin: 0 0 1.25rem;
}
@media (min-width: 576px) {
  .section-subscribe form > * {
    margin-inline: 0.625rem;
  }
}
.section-subscribe .form-control {
  border-radius: 1.56rem;
  border-color: #fff;
  height: 2.6875rem;
  font-size: 1rem;
}
@media (min-width: 576px) {
  .section-subscribe .form-control {
    min-width: 12.5rem;
  }
}
@media (min-width: 768px) {
  .section-subscribe .form-control {
    padding-inline: 1.75rem;
    min-width: 17.5rem;
  }
}
@media (min-width: 1024px) {
  .section-subscribe .form-control {
    min-width: 20rem;
  }
}
.section-subscribe .form-control::-webkit-input-placeholder {
  color: #5A5A5A;
}
.section-subscribe .form-control::-moz-placeholder {
  opacity: 1;
  color: #5A5A5A;
}
.section-subscribe .form-control:-moz-placeholder {
  color: #5A5A5A;
}
.section-subscribe .form-control:-ms-input-placeholder {
  color: #5A5A5A;
}
.section-subscribe .form-control.placeholder {
  color: #5A5A5A;
}
.section-subscribe .form-control:focus {
  border-color: #005ca3;
}
.section-subscribe .btn {
  min-width: auto;
  font-size: 0.875rem;
  padding: 0.75rem 1.875rem;
}

.section-desc {
  margin-bottom: 4.375rem;
}
@media (min-width: 1024px) {
  .section-desc {
    margin-bottom: 6.25rem;
  }
}
@media (min-width: 1470px) {
  .section-desc.desc-secondary .desc-holder {
    padding: 0 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .section-desc.desc-secondary .desc-image {
    margin-block: 1.3125rem;
  }
}
@media (min-width: 1024px) {
  .section-desc.desc-secondary .desc-text {
    margin-block: 0;
  }
}
@media (min-width: 1470px) {
  .section-desc.desc-secondary .desc-text {
    padding-left: 5.75rem;
  }
}
@media (min-width: 1024px) {
  .section-desc.desc-secondary .desc-text:after {
    top: 2.8125rem;
    left: -1.25rem;
    width: 2.8125rem;
    height: 4.5625rem;
    background-image: radial-gradient(#9ddaeb 0.16875rem, transparent 0);
    background-size: 0.5625rem 0.5625rem;
  }
}
@media (min-width: 1024px) {
  .section-desc.desc-third {
    margin-bottom: 5.625rem;
  }
}
@media (min-width: 1470px) {
  .section-desc.desc-third .desc-text {
    padding: 3.3125rem 11.5625rem 3.3125rem 3.75rem;
  }
}
@media (min-width: 1470px) {
  .section-desc.desc-third .desc-text:after {
    top: 3.75rem;
    width: 2.6875rem;
    height: 4.5625rem;
  }
}
@media (min-width: 1470px) {
  .section-desc .title-md {
    margin-bottom: 3.125rem;
  }
}

@media (min-width: 1024px) {
  .desc-holder {
    display: flex;
  }
}
@media (max-width: 1023.98px) {
  .desc-holder h2 {
    position: relative;
    min-height: 2.8125rem;
    z-index: 1;
    padding: 0 0 0 3.125rem;
  }
}
@media (max-width: 1023.98px) {
  .desc-holder h2:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 2.1875rem;
    height: 2.8125rem;
    background-image: radial-gradient(#9ddaeb 0.16875rem, transparent 0);
    background-size: 0.5625rem 0.5625rem;
    z-index: -1;
  }
}

.desc-text {
  position: relative;
  z-index: 1;
  background: #ecf1f8;
  padding: 1.875rem 1.25rem 4.0625rem;
}
@media (min-width: 768px) {
  .desc-text {
    padding: 3.125rem 3.125rem 6.875rem;
  }
}
@media (min-width: 1024px) {
  .desc-text {
    flex: 1 0;
    margin: 1.3125rem 0;
  }
}
@media (min-width: 1024px) {
  .desc-text {
    padding: 1.875rem 9.375rem 1.875rem 2.8125rem;
  }
}
@media (min-width: 1470px) {
  .desc-text {
    padding: 2.8125rem 15.625rem 2.8125rem 4.6875rem;
  }
}
@media (min-width: 1024px) {
  .desc-text:after {
    position: absolute;
    content: "";
    top: 3.4375rem;
    left: -1.0625rem;
    width: 2.1875rem;
    height: 3.4375rem;
    background-image: radial-gradient(#9ddaeb 0.16875rem, transparent 0);
    background-size: 0.5625rem 0.5625rem;
    z-index: -1;
  }
}
.desc-text > *:last-child {
  margin-bottom: 0;
}
.desc-text .btn {
  margin-top: 0.5rem;
}

.desc-image {
  position: relative;
  max-width: 90%;
  z-index: 2;
  margin: -1.875rem auto 0;
}
@media (min-width: 768px) {
  .desc-image {
    max-width: 40%;
    margin-top: -3.125rem;
  }
}
@media (min-width: 1024px) {
  .desc-image {
    max-width: 60%;
    margin: 0 0 0 -6.875rem;
    align-self: center;
  }
}
.desc-image img {
  width: 100%;
  object-fit: contain;
}
@media (min-width: 1024px) {
  .desc-image img {
    min-height: 20rem;
  }
}
@media (min-width: 1470px) {
  .desc-image img {
    min-height: 24.375rem;
  }
}

.section-programs {
  padding: 0.8125rem 0;
}
@media (min-width: 768px) {
  .section-programs {
    padding-bottom: 1.5625rem;
  }
}
@media (min-width: 1024px) {
  .section-programs {
    padding-bottom: 2.8125rem;
  }
}
.section-programs h2 {
  color: #324859;
  font-size: 2rem;
  line-height: 1.125;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .section-programs h2 {
    font-size: 2rem;
    line-height: 1.125;
    margin-bottom: 4.0625rem;
  }
}

.section-filters {
  margin-bottom: 0;
}
.section-filters + .section-subscribe {
  margin-top: 3.75rem;
}

@media (min-width: 768px) {
  .section-content {
    margin-bottom: 5rem;
  }
}
@media (min-width: 1024px) {
  .section-content {
    margin-bottom: 8.625rem;
  }
}
@media (min-width: 768px) {
  .section-content h2 {
    font-size: 2rem;
    line-height: 1.125;
  }
}
.section-content h6,
.section-content .h6 {
  color: #0082c6;
}
.section-content hr {
  margin: 0.625rem 0 1.25rem;
}

[id=footer] {
  background: #005ca3;
  color: #fff;
  padding: 2.8125rem 0 0.9375rem;
}
@media (min-width: 768px) {
  [id=footer] {
    padding-top: 65px;
  }
}
[id=footer] h5,
[id=footer] .h5 {
  color: #9ddaeb;
  margin: 0 0 1.5625rem;
}
@media (min-width: 768px) {
  [id=footer] h5,
  [id=footer] .h5 {
    margin-bottom: 1.875rem;
  }
}
[id=footer] a {
  text-decoration-color: transparent;
}
[id=footer] a:hover {
  text-decoration-color: currentColor;
}

.copy {
  display: block;
  font-size: 0.75rem;
  line-height: 1.4285714286;
  text-align: center;
  margin: 3.75rem 0 0;
}
@media (min-width: 768px) {
  .copy {
    margin-top: 6.25rem;
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  .copy {
    margin-top: 8.75rem;
  }
}

.menu {
  font-size: 1rem;
  line-height: 1.3888888889;
}
@media (min-width: 768px) {
  .menu {
    font-size: 1.125rem;
  }
}
.menu li {
  margin: 0 0 0.5rem;
}
.menu li.active a {
  text-decoration-color: currentColor;
}
.menu li:last-child {
  margin-bottom: 0;
}

.footer-holder {
  text-align: center;
  max-width: 31.25rem;
  font-size: 1.0625rem;
  line-height: 1.4117647059;
}
@media (max-width: 1469.98px) {
  .footer-holder {
    margin-inline: auto;
  }
}
@media (min-width: 768px) {
  .footer-holder .logos-main-holder {
    margin-bottom: 1.0625rem;
    padding-bottom: 1.0625rem;
  }
}
.footer-holder p,
.footer-holder address {
  margin-bottom: 0;
}
.footer-holder a {
  color: #9ddaeb;
}
.footer-holder .list-social {
  margin-top: 0.8125rem;
}
.footer-holder .list-social a {
  color: inherit;
}

.footer-logo {
  margin: 0 0 0.625rem;
}
@media (min-width: 768px) {
  .footer-logo {
    margin-bottom: 1.1875rem;
  }
}