.section-content {
    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(80);
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(138);
    }

    h2 {
        @include media-breakpoint-up(md) {
            font-size: rem-calc(32);
            line-height: calc(36/32);
        }
    }

    h6,
    .h6 {
        color: $blue-200;
    }

    hr {
        margin: rem-calc(10 0 20);
    }
}