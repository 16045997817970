// ligatured operators ≥ ≤
/* ---------------------------------------------------------
  1. Typography
  2. Сommon site styles
  3. Header site styles
  4. Components styles
  5. Footer site styles
  6. Styles for responsive breakpoints
  ---------------------------------------------------------*/

@use "sass:math";
@import '~bootstrap/scss/functions';
@import 'base/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/vendor/rfs';

@import 'base/utilities';
@import 'base/mixins';
@import 'base/helpers';

//@import 'vendors/fonts';

/* ---------------------------------------------------------
1. Typography
---------------------------------------------------------*/
@import 'base/typography';
@import 'base/base';

@import 'vendors/fonts';
@import 'vendors/slick';
@import 'vendors/simplebar';

@import 'base/form-style';

@import 'layout/common';

@import 'layout/header';

/* buttons style */
@import 'components/buttons';
@import 'components/pagination';
@import 'components/breadcrumb';
@import 'components/accordion';
@import 'components/post';
@import 'components/features-items';
@import 'components/choose-columns';
@import 'components/info-box';
@import 'components/calendar-tabs';
@import 'components/filters';
@import 'components/main-holder';
@import 'components/heading-pagination';
@import 'components/events-info';
@import 'components/menu-section-box';
@import 'components/person-item';

@import 'components/custom-slick';
@import 'components/custom-simplebar.scss';

@import 'components/gallery-cards';

@import 'components/list-social';
@import 'components/list-info';

@import 'components/cards';

@import 'components/block-find';

@import 'components/section-intro';
@import 'components/section-info';
@import 'components/section-subscribe';
@import 'components/section-desc';
@import 'components/section-programs';
@import 'components/section-filters';
@import 'components/section-content';

@import 'layout/footer';
